export const environment = {
  production: true,
  instance: 'https://login.microsoftonline.com/',
  clientId: '167f4413-2056-4905-999d-1d00c6a2a40c',
  tenantId: 'common',
  authority: 'https://login.microsoftonline.com/common',
  ishtarFunctions: 'https://functions.demo.ishtar365.com/',
  ishtarFunctionsKey:
    '5FmTbScv-QjouJc2LqVac4Jemkz3ivhQM1MmUatIw0XyAzFumLjdqw==',
  ishtar365: 'https://portal.demo.ishtar365.com',
  appIdentifier: 'resourceplanning.demo',
  cdnAppIconUrl:
    'https://ishtar365storage.blob.core.windows.net/icons/Resource_color.svg',
  ishtarFunctionsScope:
    'api://167f4413-2056-4905-999d-1d00c6a2a40c/user_impersonation',
};
