import { DateTime } from 'luxon';
import { GroupUser } from 'processdelight-angular-components';
import { parseEUDate, parseUTCDate } from 'src/app/app.component';
import { IProj } from 'src/app/components/gantt-component/project.holder';
import { DataverseObject } from '../dataverse.object';
import { Resource } from '../resource/resource';

export class Task extends DataverseObject implements IProj {
  ishtarTaskId?: string;
  name?: string;
  approvalFlow?: { id: string; name: string };
  completedTime?: number;
  deadline?: DateTime;
  decision?: { id: string; name: string };
  description?: string;
  startTime?: DateTime;
  endTime?: DateTime;
  estimatedTime?: number;
  executors?: GroupUser[];
  followers?: GroupUser[];
  isDeleted?: boolean;
  isTaskTemplate?: boolean;
  logType?: { id: string; name: string };
  number!: number;
  parentTask?: { id: string; name: string };
  priorty?: { id: string; name: string };
  progress!: number;
  progressRegistrationType?: { id: string; name: string };
  project?: { id: string; name: string };
  skill?: { id: string; name: string };
  status?: { id: string; name: string };
  type?: { id: string; name: string };
  title!: string;

  resources: Resource[] = [];

  subTasks: Task[] = [];
  expandable = false;

  daysToFinish?: number;
  holidays?: number;
  calculatedStartDate?: DateTime;
  calculatedEndDate?: DateTime;
  userIds?: GroupUser[];
  subElementFilter?: (x: IProj) => boolean;

  constructor(obj: Partial<Task>) {
    super(obj);
    Object.assign(this, obj);
    if (obj['startTime'] !== undefined)
      this.startTime = parseEUDate(obj['startTime']);
    if (obj['deadline'] !== undefined)
      this.deadline = parseEUDate(obj['deadline']);
    if (obj['endTime'] !== undefined)
      this.endTime = parseEUDate(obj['endTime']);

    if (obj['calculatedStartDate'] !== undefined)
      this.calculatedStartDate = parseEUDate(obj['calculatedStartDate']);
    if (obj['calculatedEndDate'] !== undefined)
      this.calculatedEndDate = parseEUDate(obj['calculatedEndDate']);

    if (obj['subTasks'])
      this.subTasks = obj['subTasks']
        .map((x) => new Task({ ...x, subElementFilter: this.subElementFilter }))
        .filter(this.subElementFilter ? this.subElementFilter : (x) => true);

    this.expandable = this.subTasks.length > 0;

    this.calculateChildrenTimes();
  }

  calculateChildrenTimes() {
    if (this.subElements === undefined) return;
    if (this.subElements.length > 0)
      this.startChildren = this.subElements
        .map((x) => x.startDate)
        .reduce((acc, val) =>
          val ? ((acc ? acc.toMillis() : 0) < val.toMillis() ? acc : val) : acc
        );

    if (this.subElements.length > 0)
      this.endChildren = this.subElements
        .map((x) => x.deadline)
        .reduce((acc, val) =>
          (acc ? acc.toMillis() : 0) < (val ? val.toMillis() : 0) ? acc : val
        );
  }

  class: 'project' | 'task' | 'holder' = 'task';
  startChildren?: DateTime | undefined;
  endChildren?: DateTime | undefined;

  dependentOn?: string;
  dependencyCompletionValue?: number;

  level = 1;

  override get id(): string {
    return this.ishtarTaskId || '';
  }

  get projectId(): number | undefined {
    return this.number;
  }

  get subElements(): IProj[] | undefined {
    return [...this.subTasks].sort((a, b) => a.number - b.number);
  }

  get startDate(): DateTime | undefined {
    return this.startTime;
  }

  get endDate(): DateTime | undefined {
    return this.endTime;
  }

  get users(): GroupUser[] {
    return this.followers || [];
  }

  containsUser(userId: string): boolean {
    return (
      this.users.some((u) => u.user?.id === userId) ||
      this.subElements?.some((x) => x.containsUser(userId)) ||
      false
    );
  }

  filterNode(search: string): boolean {
    return (
      this.title.toLowerCase().includes(search.toLowerCase()) ||
      this.users.some((u) =>
        u.displayName?.toLowerCase().includes(search.toLowerCase())
      )
    );
  }

  toLookUp() {
    return {
      id: this.ishtarTaskId,
      name: this.title,
    };
  }
}

export class TaskRegistrationType {
  ishtarTaskRegistrationTypeId!: string;
  type!: string;

  constructor(obj: Partial<TaskRegistrationType>) {
    Object.assign(this, obj);
  }
}
