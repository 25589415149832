import { DateTime } from 'luxon';

export class PublicHoliday {
  holidayType!: string;
  holidayDate!: DateTime;

  constructor(obj: Partial<PublicHoliday>) {
    Object.assign(this, obj);
    if (obj['holidayDate'])
      this.holidayDate = DateTime.fromISO(obj['holidayDate'] as any);
  }
}
