import { ResourceFunction } from './resourceFunction';

export class ResourceThing {
  ishtarResourceThingId?: string;
  name!: string;
  createdOn?: string;
  modifiedOn?: string;
  resourceFunctions?: ResourceFunction[];
  constructor(obj: Partial<ResourceThing>) {
    Object.assign(this, obj);
  }
  prep() {
    delete this.resourceFunctions;
    return this;
  }
}
