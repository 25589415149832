import { createAction, props } from '@ngrx/store';
import { WorkRegime } from '../../models/ishtar365/workregime';
import { Project } from '../../models/task/project';
import { ProjectMVP } from '../../models/task/projectMVP';
import { Task } from '../../models/task/task';
import { Resource } from '../../models/resource/resource';

export const getResourceCalc = createAction(
  '[Tasks] Get Resource Calculation data',
  props<{
    callback?: () => void;
    assignedToMe: boolean;
    mockDate?: string;
  }>()
);

export const getResourceCalcResolved = createAction(
  '[Tasks] Get Resource Calculation data resolved',
  props<{
    tasks: Task[];
    projects: Project[];
  }>()
);

export const getTasks = createAction(
  '[Tasks] Get Tasks',
  props<{
    callback?: () => void;
  }>()
);

export const getTasksResolved = createAction(
  '[Tasks] Get Tasks resolved',
  props<{
    tasks: Task[];
  }>()
);

export const getProjects = createAction(
  '[Tasks] Get Projects',
  props<{
    callback?: () => void;
  }>()
);

export const getProjectsResolved = createAction(
  '[Tasks] Get Projects resolved',
  props<{
    projects: Project[];
  }>()
);

export const getProjectsMVP = createAction(
  '[Tasks] Get Projects MVP',
  props<{
    callback?: () => void;
  }>()
);

export const getProjectsMVPResolved = createAction(
  '[Tasks] Get Projects MVP resolved',
  props<{
    projects: ProjectMVP[];
  }>()
);

export const getWorkRegimes = createAction(
  '[Tasks] Get Work Regimes',
  props<{
    callback?: () => void;
  }>()
);

export const getWorkRegimesResolved = createAction(
  '[Tasks] Get Work Regimes resolved',
  props<{
    regimes: WorkRegime[];
  }>()
);

export const setTaskResources = createAction(
  '[Tasks] Update task resources',
  props<{
    resources: Resource[];
  }>()
);
export const addTaskResources = createAction(
  '[Tasks] Add task resources',
  props<{
    resources: Resource[];
  }>()
);
export const patchTaskResources = createAction(
  '[Tasks] Patch task resources',
  props<{
    resources: Resource[];
    taskId: string;
  }>()
);
export const removeTaskResources = createAction(
  '[Tasks] Remove task resources',
  props<{
    resources: string[];
  }>()
);
