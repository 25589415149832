import { DateTime } from 'luxon';
import { ResourceUser } from './resourceUser';
import { ResourceFunction } from './resourceFunction';
export class Resource {
  ishtarResourceId!: string;
  title!: string;
  task?: { id?: string; name?: string };
  function?: ResourceFunction | null;
  resourceUser?: ResourceUser | null;
  taskCapacity?: number;
  id?: string;
  modifiedOn?: DateTime;
  constructor(obj: Partial<Resource>) {
    Object.assign(this, obj);
  }
}
