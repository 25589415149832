import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  flatMap,
  forkJoin,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { UserLicenseInfo } from '../models/ishtar365/userlicenseinfo';
import { IshtarResourcePlanningService } from './resourceplanning.service';
import { AppInfo } from '../models/resource/appinfo';
import { TimeSort } from '../models/resource/timesort';
import { TaskRegistrationType } from '../models/task/task';
import { ProjectType } from '../models/task/project';
import { ResourceFacade } from '../store/resource/resource.facade';
import { TaskFacade } from '../store/task/task.facade';
import { Status } from '../models/task/status';
import {
  GroupUser,
  Ishtar365CommunicationService,
  TranslationService,
} from 'processdelight-angular-components';
import { ActivatedRoute, Router } from '@angular/router';

export const varlicense$ = new BehaviorSubject<UserLicenseInfo | undefined>(
  undefined
);
export const isAdmin = (ishtarApp = 'Ishtar.Resource') =>
  varlicense$.pipe(
    filter((license) => !!license),
    map((license) =>
      license?.licenses.some((l) => l.productName === ishtarApp && l.isAdmin)
    ),
    startWith(false)
  );

export const appinfo$ = new BehaviorSubject<AppInfo | undefined>(undefined);
export const users$ = new BehaviorSubject<GroupUser[] | undefined>(undefined);
export const licensedTaskResourceUsers$ = new BehaviorSubject<
  GroupUser[] | undefined
>(undefined);
export const taskUsers$ = new BehaviorSubject<GroupUser[] | undefined>(
  undefined
);
export const timeSorts$ = new BehaviorSubject<TimeSort[] | undefined>(
  undefined
);
export const taskRegistrationTypes$ = new BehaviorSubject<
  TaskRegistrationType[] | undefined
>(undefined);
export const projectTypes$ = new BehaviorSubject<ProjectType[] | undefined>(
  undefined
);
export const statuses$ = new BehaviorSubject<Status[] | undefined>(undefined);

@Injectable({
  providedIn: 'root',
})
export class StartUpService {
  constructor(
    private ishtarResourcePlanningService: IshtarResourcePlanningService,
    private resFac: ResourceFacade,
    private taskFac: TaskFacade,
    private translationService: TranslationService,
    private i365com: Ishtar365CommunicationService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    combineLatest([users$, taskUsers$])
      .pipe(
        filter(([u, tu]) => !!u && !!tu),
        map(([u]) =>
          u?.filter((user) =>
            taskUsers$.value?.some((tUser) => tUser.xId === user.xId)
          )
        ) // Crosscheck with taskUsers bcs theyre only allowed if they have license for Resource & Task
      )
      .subscribe((u) => licensedTaskResourceUsers$.next(u!));
  }

  getLicense() {
    this.ishtarResourcePlanningService
      .getLicense()
      .pipe(first())
      .subscribe((data: any) => {
        varlicense$.next(data);
      });
  }

  getAppinfo() {
    return this.ishtarResourcePlanningService.getAppInfo().pipe(
      first(),
      tap((data) => {
        appinfo$.next(data);
      })
    );
  }

  getTypes() {
    this.ishtarResourcePlanningService
      .getTypes()
      .pipe(first())
      .subscribe((data) => {
        projectTypes$.next(data.projectTypes);
        taskRegistrationTypes$.next(data.registrationType);
        users$.next(data.resourceUser);
        licensedTaskResourceUsers$.next(data.resourceAndTaskUsers);
        statuses$.next(data.status);
        timeSorts$.next(data.timeSort);
      });
  }

  getTranslations() {
    return this.ishtarResourcePlanningService.getTranslations().pipe(
      first(),
      tap((data) => {
        this.translationService.update(data);
      })
    );
  }

  boot() {
    this.getLicense();
    return varlicense$.pipe(
      filter((license) => !!license),
      first(),
      switchMap(() => {
        try {
          this.i365com.init();
          this.i365com.registerRedirectAction('Task', (id) => {
            const segments = this.activatedRoute.snapshot.url.map(
              (s) => s.path
            );
            if (segments[0] !== 'planning') {
              this.router.navigate(['planning'], { queryParams: { task: id } });
            }
          });
        } catch (error) {
          // not in frame
        }
        this.getTypes();
        this.resFac.getAppConfig();
        this.resFac.getInitialLoad();
        return forkJoin([this.getTranslations(), this.getAppinfo()]);
      })
    );
  }
}
