import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AADUser,
  Group,
  GroupUser,
  MicrosoftAuthenticationService,
} from 'processdelight-angular-components';
import { Observable, filter, first, map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppInfo } from '../models/resource/appinfo';
import { Resource } from '../models/resource/resource';
import { ResourceFunction } from '../models/resource/resourceFunction';
import { ResourceThing } from '../models/resource/resourceThing';
import { ResourceUser } from '../models/resource/resourceUser';
import { TimeSort } from '../models/resource/timesort';
import { UserLicenseInfo } from '../models/ishtar365/userlicenseinfo';
import { varlicense$ } from './startup.service';
import { Task, TaskRegistrationType } from '../models/task/task';
import { Project, ProjectType } from '../models/task/project';
import { ProjectMVP } from '../models/task/projectMVP';
import { PublicHoliday } from '../models/ishtar365/publicHoliday';
import { WorkRegime } from '../models/ishtar365/workregime';
import { AppConfig } from '../models/app.config';
import { Status } from '../models/task/status';

@Injectable({
  providedIn: 'root',
})
export class BetterHttpClient {
  headers = new HttpHeaders();
  tenantId = '';
  envUrl = '';

  constructor(
    private httpClient: HttpClient,
    private msal: MicrosoftAuthenticationService
  ) {
    if (environment.ishtarFunctionsKey.trim() !== '')
      this.headers = this.headers.set('code', environment.ishtarFunctionsKey);
    varlicense$
      .pipe(
        filter((t) => t !== undefined),
        first()
      )
      .subscribe((u) => {
        this.headers.append('ImpersonationUserAADId', u?.microsoftId || '');
        this.tenantId = u?.tenantId || this.tenantId;
        this.envUrl = u?.dataverseEnvironmentUrl || this.envUrl;
      });
  }

  private createApiEndpointUrl(path: string, domain: string) {
    const apiBase = `${environment.ishtarFunctions}/api/${domain}/${this.tenantId}/${this.envUrl}`;
    const url = new URL(`${apiBase}/${path}`);
    if (environment.ishtarFunctionsKey.trim() !== '')
      url.searchParams.append('code', environment.ishtarFunctionsKey);
    return url.toString();
  }

  camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: this.camelcaseKeys(
            e[1]
          ),
        }),
        {}
      );
    else return obj;
  }

  get<T>(
    url: string,
    resContstructor?: (t: any) => T,
    domain = 'dataverse',
    headers?: HttpHeaders
  ): Observable<T> {
    headers = headers || this.headers;
    return this.httpClient
      .get<T>(this.createApiEndpointUrl(url, domain), {
        headers: headers,
      })
      .pipe(
        first(),
        map((group) => (resContstructor ? resContstructor(group) : group))
      );
  }

  getList<T>(
    url: string,
    resContstructor?: (t: any) => T,
    domain = 'dataverse'
  ): Observable<T[]> {
    return this.httpClient
      .get<T[]>(this.createApiEndpointUrl(url, domain), {
        headers: this.headers,
      })
      .pipe(
        first(),
        map((group) =>
          resContstructor ? group.map((g) => resContstructor(g)) : group
        )
      );
  }

  post<T>(
    url: string,
    body: any,
    resContstructor?: (t: any) => T,
    domain = 'dataverse'
  ): Observable<T> {
    return this.httpClient
      .post<T>(this.createApiEndpointUrl(url, domain), body, {
        headers: this.headers,
      })
      .pipe(
        first(),
        map((group) => (resContstructor ? resContstructor(group) : group))
      );
  }

  postList<T>(
    url: string,
    body: any,
    resContstructor?: (t: any) => T,
    domain = 'dataverse'
  ): Observable<T[]> {
    return this.httpClient
      .post<T[]>(this.createApiEndpointUrl(url, domain), body, {
        headers: this.headers,
      })
      .pipe(
        first(),
        map((group) =>
          resContstructor ? group.map((g) => resContstructor(g)) : group
        )
      );
  }

  patch<T>(
    url: string,
    body: any,
    resContstructor?: (t: any) => T,
    domain = 'dataverse'
  ): Observable<T> {
    return this.httpClient
      .patch<T>(this.createApiEndpointUrl(url, domain), body, {
        headers: this.headers,
      })
      .pipe(map((x) => (resContstructor ? resContstructor(x) : x)));
  }

  patchList<T>(
    url: string,
    body: any,
    resContstructor?: (t: any) => T,
    domain = 'dataverse'
  ): Observable<T[]> {
    return this.httpClient
      .patch<T[]>(this.createApiEndpointUrl(url, domain), body, {
        headers: this.headers,
      })
      .pipe(
        first(),
        map((group) =>
          resContstructor ? group.map((g) => resContstructor(g)) : group
        )
      );
  }

  delete<T>(url: string, body: any, domain = 'dataverse'): Observable<T> {
    return this.httpClient.delete<T>(this.createApiEndpointUrl(url, domain), {
      headers: this.headers,
      body: body,
    });
  }
  deleteList<T>(url: string, body: any, domain = 'dataverse'): Observable<T[]> {
    return this.httpClient.delete<T[]>(this.createApiEndpointUrl(url, domain), {
      headers: this.headers,
      body: body,
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class IshtarResourcePlanningService {
  apiBase = `${environment.ishtarFunctions}/api`;

  constructor(
    private httpClient: HttpClient,
    private betterhttpClient: BetterHttpClient,
    private msal: MicrosoftAuthenticationService
  ) {}

  private createApiEndpointUrl(path: string) {
    const url = new URL(`${this.apiBase}/${path}`);
    if (environment.ishtarFunctionsKey.trim() !== '')
      url.searchParams.append('code', environment.ishtarFunctionsKey);
    return url.toString();
  }

  getLicense() {
    const url = new URL(
      `${environment.ishtarFunctions}/api/license/${this.msal.tenantId}/${this.msal.email}`
    );
    if (environment.ishtarFunctionsKey.trim() !== '')
      url.searchParams.append('code', environment.ishtarFunctionsKey);
    return this.httpClient.get<UserLicenseInfo>(url.toString());
  }

  getConfig() {
    return this.httpClient
      .get<AppConfig>(
        this.createApiEndpointUrl(
          `subscription/${this.msal.tenantId}/Ishtar.Resource/config`
        )
      )
      .pipe(map((c) => new AppConfig(c)));
  }

  saveConfig(config: AppConfig) {
    return this.httpClient
      .post<AppConfig>(
        this.createApiEndpointUrl(
          `subscription/${this.msal.tenantId}/Ishtar.Resource/config`
        ),
        config
      )
      .pipe(map((c) => new AppConfig(c)));
  }

  getAllLicenses() {
    return this.httpClient.get<UserLicenseInfo[]>(
      this.createApiEndpointUrl(`license/${this.msal.tenantId}/`)
    );
  }

  getAppInfo() {
    return this.httpClient.get<AppInfo>(
      this.createApiEndpointUrl(`apps/Ishtar.Resource`)
    );
  }

  getUsers() {
    return this.httpClient
      .get<GroupUser[]>(
        this.createApiEndpointUrl(`users/${this.msal.tenantId}/Ishtar.Resource`)
      )
      .pipe(
        map((user) =>
          user.map((u) =>
            this.camelcaseKeys(
              new GroupUser({
                user: new AADUser(this.camelcaseKeys(u).user),
              })
            )
          )
        )
      );
  }

  getTaskUsers() {
    return this.httpClient
      .get<GroupUser[]>(
        this.createApiEndpointUrl(`users/${this.msal.tenantId}/Ishtar.Tasks`)
      )
      .pipe(
        map((user) =>
          user.map(
            (u) =>
              new GroupUser({
                user: new AADUser(this.camelcaseKeys(u).user),
              })
          )
        )
      );
  }

  getGroups() {
    return this.httpClient
      .get<GroupUser[]>(
        this.createApiEndpointUrl(
          `groups/${this.msal.tenantId}/Ishtar.Resource`
        )
      )
      .pipe(
        map((group) => group.map((g) => new GroupUser(this.camelcaseKeys(g))))
      );
  }

  getTranslations() {
    return this.httpClient.get<any>(
      this.createApiEndpointUrl(
        `ishtarapps/translations?lang=${varlicense$.value?.language}`
      )
    );
  }

  getTimeSorts() {
    return this.httpClient
      .get<TimeSort[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarResourceTimeSort`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value?.microsoftId || '',
          },
        }
      )
      .pipe(
        map((timeSorts) =>
          timeSorts.map((t) => new TimeSort(this.camelcaseKeys(t)))
        )
      );
  }

  getTaskRegistrationTypes() {
    return this.betterhttpClient.getList<TaskRegistrationType>(
      'IshtarTaskRegistrationType',
      (t) => new TaskRegistrationType(this.camelcaseKeys(t))
    );
  }

  getProjectType() {
    return this.betterhttpClient.getList<ProjectType>(
      'IshtarProjectType',
      (t) => new ProjectType(this.camelcaseKeys(t))
    );
  }

  getPublicHoliday(year: number) {
    return this.httpClient
      .get<PublicHoliday[]>(
        this.createApiEndpointUrl(`publicholiday/${year}/all`)
      )
      .pipe(
        map((holidays) =>
          holidays.map((h) => new PublicHoliday(this.camelcaseKeys(h)))
        )
      );
  }

  getResources() {
    return this.betterhttpClient.getList<Resource>(
      'IshtarResource',
      (t) => new Resource(this.camelcaseKeys(t))
    );
  }

  addResources(r: Resource[]) {
    return this.betterhttpClient.postList<Resource>(
      'IshtarResource/records',
      r.map((res) =>
        this.capitalizeKeys(res, [
          'ModifiedBy',
          'CreatedBy',
          'ResourceUser',
          'Function',
          'Task',
        ])
      ),
      (t) => new Resource(this.camelcaseKeys(t))
    );
  }

  patchResources(r: Resource[], taskId: string) {
    return this.betterhttpClient.patchList<Resource>(
      `resources/${taskId}`,
      r.map((res) =>
        this.capitalizeKeys(res, [
          'ModifiedBy',
          'CreatedBy',
          'ResourceUser',
          'Function',
          'Task',
        ])
      ),
      (t) => new Resource(this.camelcaseKeys(t)),
      'ishtar/resource'
    );
  }

  deleteResources(r: string[]) {
    return this.betterhttpClient.deleteList<string>(
      'IshtarResource/records',
      r
    );
  }

  getResourceThings() {
    return this.betterhttpClient.getList<ResourceThing>(
      'IshtarResourceThing',
      (r) => new ResourceThing(this.camelcaseKeys(r))
    );
  }

  updateResourceThings(resourceThings: ResourceThing[]) {
    return this.betterhttpClient.patchList<ResourceThing>(
      'IshtarResourceThing/records',
      resourceThings.map((r) => this.capitalizeKeys(r)),
      (r) => new ResourceThing(this.camelcaseKeys(r))
    );
  }

  removeResourceThings(ids: string[]) {
    return this.betterhttpClient.deleteList<string>(
      'IshtarResourceThing/records',
      ids
    );
  }

  addResourceThings(resourceThings: ResourceThing[]) {
    const addedResourceThings = resourceThings.map((r) =>
      this.capitalizeKeys(r)
    );
    return this.httpClient
      .post<ResourceThing[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarResourceThing/records`
        ),
        addedResourceThings,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((resourceThings) =>
          resourceThings.map((r) => new ResourceThing(this.camelcaseKeys(r)))
        )
      );
  }

  //

  getResourceFunctions() {
    return this.httpClient
      .get<ResourceFunction[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${varlicense$.value?.dataverseEnvironmentUrl}/IshtarResourceFunction?include=ResourceThing`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value?.microsoftId || '',
          },
        }
      )
      .pipe(
        map((resourceFunctions: ResourceFunction[]) =>
          resourceFunctions.map(
            (r) => new ResourceFunction(this.camelcaseKeys(r))
          )
        )
      );
  }

  updateResourceFunctions(resourceFunctions: ResourceFunction[]) {
    return this.httpClient
      .patch<ResourceFunction[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarResourceFunction/records?include=ResourceThing`
        ),
        resourceFunctions.map((r) => this.capitalizeKeys(r)),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((resourceFunctions) =>
          resourceFunctions.map(
            (r) => new ResourceFunction(this.camelcaseKeys(r))
          )
        )
      );
  }

  removeResourceFunctions(id: string[]) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarResourceFunction/records`
      ),
      {
        body: id,
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  addResourceFunctions(resourceFunctions: ResourceFunction[]) {
    const addedResourceFunctions = resourceFunctions.map((r) =>
      this.capitalizeKeys(r, ['ResourceThing', 'TimeSort'])
    );
    return this.httpClient
      .post<ResourceFunction[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarResourceFunction/records?include=IshtarResourceThing&select=IshtarResourceThing`
        ),
        addedResourceFunctions,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((resourceFunctions) =>
          resourceFunctions.map(
            (r) => new ResourceFunction(this.camelcaseKeys(r))
          )
        )
      );
  }

  getResourceUsers() {
    return this.betterhttpClient.getList<ResourceUser>(
      'IshtarResourceUser',
      (u) => new ResourceUser(this.camelcaseKeys(u))
    );
  }

  addResourceUser(body: ResourceUser[]) {
    return this.betterhttpClient
      .post<ResourceUser[]>(
        'IshtarResourceUser/records',
        this.capitalizeKeys(body)
      )
      .pipe(
        map((u) => u.map((ru) => new ResourceUser(this.camelcaseKeys(ru))))
      );
  }

  patchResourceUser(body: ResourceUser[]) {
    return this.betterhttpClient
      .patch<ResourceUser[]>(
        'IshtarResourceUser/records',
        this.capitalizeKeys(body)
      )
      .pipe(
        map((u) => u.map((ru) => new ResourceUser(this.camelcaseKeys(ru))))
      );
  }

  deleteResourceUser(body: string[]) {
    return this.betterhttpClient.delete<string[]>(
      'IshtarResourceUser/records',
      body
    );
  }

  getTasks() {
    return this.betterhttpClient.getList<Task>(
      'IshtarTask',
      (t) => new Task(this.camelcaseKeys(t))
    );
  }

  getProjects() {
    return this.betterhttpClient.getList<Project>(
      'IshtarProject',
      (t) => new Project(this.camelcaseKeys(t))
    );
  }

  getProjectsMVP() {
    return this.betterhttpClient.getList<ProjectMVP>(
      'IshtarProjectMVP',
      (t) => new ProjectMVP(this.camelcaseKeys(t))
    );
  }

  getWorkRegimes() {
    return this.betterhttpClient.getList<WorkRegime>(
      'IshtarWorkRegime',
      (t) => new WorkRegime(this.camelcaseKeys(t))
    );
  }

  getTypes() {
    return this.betterhttpClient
      .get<{
        projectTypes: ProjectType[];
        registrationTypes: TaskRegistrationType[];
        resourceUsers: GroupUser[];
        resourceAndTaskUsers: GroupUser[];
        statuses: Status[];
        timeSorts: TimeSort[];
      }>('gettypes', undefined, 'ishtar/resource')
      .pipe(
        map((types) => ({
          projectTypes: types.projectTypes.map(
            (t) => new ProjectType(this.camelcaseKeys(t))
          ),
          registrationType: types.registrationTypes.map(
            (t) => new TaskRegistrationType(this.camelcaseKeys(t))
          ),
          resourceUser: types.resourceUsers.map(
            (u) => new GroupUser(this.camelcaseKeys(u))
          ),
          resourceAndTaskUsers: types.resourceAndTaskUsers.map(
            (u) => new GroupUser(this.camelcaseKeys(u))
          ),
          status: types.statuses.map((s) => new Status(this.camelcaseKeys(s))),
          timeSort: types.timeSorts.map(
            (t) => new TimeSort(this.camelcaseKeys(t))
          ),
        }))
      );
  }

  getResourceCalc(assignedToMe: boolean, mockDate?: string) {
    return this.betterhttpClient
      .get<{
        projects: Project[];
        tasks: Task[];
      }>(
        `resources?assignedToMe=${assignedToMe}`,
        undefined,
        'ishtar/resource',
        mockDate !== undefined
          ? new HttpHeaders({
              mockDate: mockDate,
            })
          : undefined
      )
      .pipe(
        map((x) => ({
          tasks: x.tasks.map((t) => new Task(this.camelcaseKeys(t))),
          projects: x.projects.map((p) => new Project(this.camelcaseKeys(p))),
        }))
      );
  }

  getInitialLoad() {
    return this.betterhttpClient
      .get<{
        resources: Resource[];
        resourceThings: ResourceThing[];
        resourceFunctions: ResourceFunction[];
        resourceUsers: ResourceUser[];
      }>('initial', undefined, 'ishtar/resource')
      .pipe(
        map((x) => ({
          resources: x.resources.map(
            (r) => new Resource(this.camelcaseKeys(r))
          ),
          resourceThings: x.resourceThings.map(
            (rt) => new ResourceThing(this.camelcaseKeys(rt))
          ),
          resourceFunctions: x.resourceFunctions.map(
            (rf) => new ResourceFunction(this.camelcaseKeys(rf))
          ),
          resourceUsers: x.resourceUsers.map(
            (ru) => new ResourceUser(this.camelcaseKeys(ru))
          ),
        }))
      );
  }

  // Helpers

  camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: this.camelcaseKeys(
            e[1]
          ),
        }),

        {}
      );
    else return obj;
  }

  capitalizeKeys(
    obj: any,
    ignoredProperties: string[] = ['ModifiedBy', 'CreatedBy', 'TimeSort']
  ): any {
    const ignoredPropertiesLower = ignoredProperties.map((p) =>
      p.toLowerCase()
    );

    if (Array.isArray(obj))
      return [...obj.map((o) => this.capitalizeKeys(o, ignoredProperties))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toUpperCase() + e[0].slice(1)]:
            ignoredPropertiesLower.includes(e[0].toLowerCase())
              ? e[1]
              : this.capitalizeKeys(e[1], ignoredProperties),
        }),

        {}
      );
    else return obj;
  }
}
