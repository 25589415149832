import { IProj } from 'src/app/components/gantt-component/project.holder';
import { ProjectMVP } from './projectMVP';
import { Task } from './task';
import { parseEUDate, parseUTCDate } from 'src/app/app.component';
import { DateTime } from 'luxon';
import { DataverseObject } from '../dataverse.object';
import { AADUser, GroupUser } from 'processdelight-angular-components';

export class Project extends DataverseObject implements IProj {
  id!: string;
  ishtarProjectId!: string;
  projectName!: string;
  calculateProgress?: boolean;
  client?: { id: string; name: string };
  deadline?: DateTime;
  description?: string;
  members?: string;
  prestudy?: boolean;
  parent?: Project;
  productOwner?: GroupUser;
  projectChannel?: string;
  projectId?: number;
  projectType?: { id: string; name: string };
  projectVersion?: number;
  startDate?: DateTime;
  status?: { id: string; name: string };

  subProjects: Project[] = [];
  mvps: ProjectMVP[] = [];
  tasks: Task[] = [];

  class: 'project' | 'task' | 'holder' = 'project';

  progress?: number | undefined;
  calculatedStartDate?: DateTime;
  calculatedEndDate?: DateTime;

  dependentOn?: string;
  dependencyCompletionValue?: number;
  subElementFilter?: (x: IProj) => boolean;

  level = 0;

  get title(): string {
    return this.projectName;
  }

  get subElements(): IProj[] {
    return (
      [...this.subProjects].sort(
        (a, b) => (a?.projectId || 0) - (b?.projectId || 0)
      ) as IProj[]
    ).concat(this.tasks);
  }

  get users(): GroupUser[] {
    return this.productOwner ? [this.productOwner] : [];
  }

  containsUser(userId: string): boolean {
    return (
      this.productOwner?.user?.id === userId ||
      this.users.some((u) => u.user?.id === userId) ||
      this.subElements?.some((x) => x.containsUser(userId)) ||
      false
    );
  }

  filterNode(search: string): boolean {
    return (
      this.title.toLowerCase().includes(search.toLowerCase()) ||
      this.productOwner?.user?.displayName
        ?.toLowerCase()
        .includes(search.toLowerCase()) ||
      this.users.some((u) =>
        u.displayName?.toLowerCase().includes(search.toLowerCase())
      )
    );
  }

  expandable = true;

  constructor(obj: Partial<Project>) {
    super(obj);
    Object.assign(this, obj);
    if (!this.mvps) this.mvps = [];
    if (obj['startDate']) this.startDate = parseEUDate(obj['startDate']);
    if (obj['deadline']) {
      this.deadline = parseEUDate(obj['deadline']);
    }
    if (obj['calculatedStartDate'] !== undefined)
      this.calculatedStartDate = parseUTCDate(obj['calculatedStartDate']);
    if (obj['calculatedEndDate'] !== undefined)
      this.calculatedEndDate = parseUTCDate(obj['calculatedEndDate']);

    if (obj['tasks'])
      this.tasks = obj['tasks']
        .map((t) => new Task({ ...t, subElementFilter: this.subElementFilter }))
        .sort((a, b) => (a?.projectId || 0) - (b?.projectId || 0))
        .filter(this.subElementFilter ? this.subElementFilter : (x) => true);

    this.subProjects = this.subProjects
      .map(
        (p) => new Project({ ...p, subElementFilter: this.subElementFilter })
      )
      .filter(this.subElementFilter ? this.subElementFilter : (x) => true);

    this.expandable = this.subProjects.length > 0 || this.tasks.length > 0;
  }
}

export class ProjectType {
  id?: string;
  estimationParam?: { id: string; name: string };
  hasEstimation?: boolean;
  title!: string;
  constructor(obj: Partial<ProjectType>) {
    Object.assign(this, obj);
  }
}
