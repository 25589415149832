import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Subject } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { resourceThingState } from './resource.reducer';
import {
  getResourceThings,
  addResourceThings,
  removeResourceThings,
  updateResourceThings,
  addResourceFunctions,
  getResourceFunctions,
  removeResourceFunctions,
  updateResourceFunctions,
  getResourceUser,
  addResourceUser,
  patchResourceUser,
  deleteResourceUser,
  getResource,
  addResource,
  patchResources,
  removeResource,
  patchAppConfig,
  getAppConfig,
  getInitialLoad,
} from './resource.actions';
import { ResourceThing } from '../../models/resource/resourceThing';
import { ResourceFunction } from '../../models/resource/resourceFunction';
import { ResourceUser } from '../../models/resource/resourceUser';
import { Resource } from '../../models/resource/resource';
import { AppConfig } from '../../models/app.config';

@Injectable({
  providedIn: 'root',
})
export class ResourceFacade {
  appConfig$ = this._store.pipe(
    select(resourceThingState),
    map((state) => state.appConfig)
  );
  resourceThings$ = this._store.pipe(
    select(resourceThingState),
    map((state) => state.resourceThings)
  );
  resourceFunctions$ = this._store.pipe(
    select(resourceThingState),
    map((state) => state.resourceFunctions)
  );

  // TODO: remove empty resourceUser objects from db
  resourceUsers$ = this._store.pipe(
    select(resourceThingState),
    map((state) => state.resourceUsers?.filter((f) => !!f.user))
  );

  resources$ = this._store.pipe(
    select(resourceThingState),
    map((state) => state.resources)
  );

  constructor(private _store: Store<AppState>) {}

  getAppConfig(callback?: () => void): void {
    this._store.dispatch(
      getAppConfig({
        callback,
      })
    );
  }

  getInitialLoad() {
    const subject = new Subject<void>();
    this._store.dispatch(
      getInitialLoad({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  saveAppConfig(config: AppConfig, callback?: () => void) {
    this._store.dispatch(
      patchAppConfig({
        config,
        callback,
      })
    );
  }

  getResources$(callback?: () => void) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getResource({
        callback: () => {
          callback ? callback() : undefined;
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  addResources(resources: Resource[], callback?: () => void) {
    if (resources.length === 0) {
      if (callback) callback();
      return;
    }
    this._store.dispatch(addResource({ resources, callback }));
  }

  patchResources(resources: Resource[], taskId: string, callback?: () => void) {
    if (resources.length === 0) {
      if (callback) callback();
      return;
    }
    this._store.dispatch(patchResources({ resources, taskId, callback }));
  }

  deleteResources(resources: string[], callback?: () => void) {
    if (resources.length === 0) {
      if (callback) callback();
      return;
    }
    this._store.dispatch(removeResource({ resources, callback }));
  }

  getResourceThings$() {
    const subject = new Subject<void>();
    this._store.dispatch(
      getResourceThings({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  addResourceThings(
    resourceThings: ResourceThing[],
    callback?: (addedResourceThings: ResourceThing[]) => void
  ) {
    this._store.dispatch(addResourceThings({ resourceThings, callback }));
  }

  removeResourceThings(ids: string[]) {
    const subject = new Subject<void>();
    this._store.dispatch(
      removeResourceThings({
        ids,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  updateResourceThings(resourceThings: ResourceThing[], callback?: () => void) {
    this._store.dispatch(updateResourceThings({ resourceThings, callback }));
  }

  getResourceFunctions$() {
    const subject = new Subject<void>();
    this._store.dispatch(
      getResourceFunctions({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  addResourceFunctions(
    resourceFunctions: ResourceFunction[],
    callback?: () => void
  ) {
    this._store.dispatch(addResourceFunctions({ resourceFunctions, callback }));
  }

  removeResourceFunction(ids: string[], callback?: () => void) {
    this._store.dispatch(removeResourceFunctions({ ids, callback }));
  }

  updateResourceFunctions(
    resourceFunctions: ResourceFunction[],
    callback?: () => void
  ) {
    this._store.dispatch(
      updateResourceFunctions({ resourceFunctions, callback })
    );
  }

  getResourceUsers(callback?: () => void) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getResourceUser({
        callback: () => {
          subject.next();
          subject.complete();
          callback ? callback() : undefined;
        },
      })
    );
    return subject;
  }

  addResourceUser(user: ResourceUser[], callback?: () => void) {
    this._store.dispatch(addResourceUser({ user, callback }));
  }

  patchResourceUser(user: ResourceUser[], callback?: () => void) {
    this._store.dispatch(patchResourceUser({ user, callback }));
  }

  deleteResourceUser(user: string[], callback?: () => void) {
    this._store.dispatch(deleteResourceUser({ user, callback }));
  }
}
