export class AppConfig {
  actualTaskColor!: string;
  estimatedTaskColor!: string;

  projectColor!: string;
  progressColor!: string;
  earlyColor!: string;
  lateColor!: string;

  constructor(obj: Partial<AppConfig>) {
    Object.assign(this, obj);
    if (this.actualTaskColor === undefined)
      this.actualTaskColor = 'rgb(0, 0, 0)';

    // foreach preset color, if it's not defined, set it to the default value
    for (const color of this.presetColors) {
      if ((this as any)[color.name] === undefined)
        (this as any)[color.name] = color.value;
    }
  }

  presetColors = [
    {
      name: 'actualTaskColor',
      styleName: 'actual-task',
      value: '#5a5959',
    },
    {
      name: 'estimatedTaskColor',
      styleName: 'estimated-task',
      value: '#818181',
    },
    {
      name: 'projectColor',
      styleName: 'project-color',
      value: 'rgba(255, 255, 255, 0.2)',
    },
    {
      name: 'progressColor',
      styleName: 'actual-task-progress',
      value: '#2fff3d',
    },
    { name: 'earlyColor', styleName: 'early-task', value: '#88fa97' },
    { name: 'lateColor', styleName: 'late-task', value: '#970000b3' },
  ];
}
