<div class="infocont" *ngIf="activeResourceThing">
  <div class="info">
    <div class="">
      <mat-form-field class="betterinput">
        <mat-label>{{translations.name}}</mat-label>
        <input matInput [formControl]="getThingTitleControl()" />
      </mat-form-field>
      <div class="title">
        <span class="pt-2">{{translations.functions}}</span>
        <div class="d-flex gap-2">
          <mat-spinner *ngIf="addingnew" class="smallspinner"></mat-spinner>
          <button
            mat-stroked-button
            color="primary"
            [disabled]="addingnew"
            (click)="addNewFunction()"
          >
            {{translations.new}}
          </button>
        </div>
      </div>
      <div class="functions">
        <mat-card
          *ngFor="let func of getThingResourceFunctionLineGroups()"
          (click)="selectFunc(func)"
          [class]="isActiveFunc(func) ? 'activefunc' : ''"
        >
          <mat-card-content class="func p-2 ps-3">
            <span>
              {{ func.value.name }}
            </span>
            <span class="pristineIndic" *ngIf="!func.pristine"> ⬤ </span>
            <button
              mat-stroked-button
              *ngIf="!isActiveFunc(func)"
              (click)="selectFunc(func)"
              class="selectfunc"
            >
              {{translations.select}}
            </button>
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              class="optionsBtn"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="deleteFunc(func); $event.stopPropagation()"
              >
                {{translations.delete}}
              </button>
            </mat-menu>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <mat-divider [vertical]="true"></mat-divider>

    <div class="d-flex flex-column gap-3 p-1" *ngIf="activeResourceFunction">
      <!--
      <div class="title">
        <span>
          {{ getFunctionTitleControl(activeResourceFunction).value }}</span
        >
        <div>
          <button mat-flat-button color="primary" (click)="saveFunc()">
            Save
          </button>
          <button mat-button color="warn" (click)="cancelFunc()">Cancel</button>
        </div>
      </div>
    -->
      <mat-form-field class="betterinput">
        <mat-label>{{translations.name}}</mat-label>
        <input
          matInput
          [formControl]="getFunctionTitleControl(activeResourceFunction)"
        />
      </mat-form-field>
      <mat-form-field class="betterinput">
        <mat-label>{{translations.description}}</mat-label>
        <textarea
          matInput
          [formControl]="getFunctionDescriptionControl(activeResourceFunction)"
        >
        </textarea>
      </mat-form-field>
      <div class="timecalc">
        <mat-form-field>
          <mat-label>{{translations.timeAmount}}</mat-label>
          <input
            matInput
            type="number"
            min="0"
            [formControl]="getFunctionTimeAmount(activeResourceFunction)"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{translations.timeUnit}}</mat-label>
          <mat-select
            [formControl]="getFunctionTimeSort(activeResourceFunction)"
          >
            <mat-option
              [value]="timeSort.ishtarResourceTimeSortId"
              *ngFor="let timeSort of timeSort$ | async"
            >
              {{ timeSort.sort }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{translations.capacity}}</mat-label>
          <input
            matInput
            type="number"
            min="0"
            [formControl]="getFunctionCapacity(activeResourceFunction)"
          />
        </mat-form-field>
        <div>
          <span>{{translations.totalTimespan}}</span>
          <span
            >{{ getTotalFunctionTimeAmount(activeResourceFunction) }}
            {{ getTimeSortName(activeResourceFunction) }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row align-items-center gap-2">
    <button mat-flat-button color="warn" (click)="close()">{{translations.cancel}}</button>
    <button mat-flat-button color="primary" (click)="saveItem()">{{translations.save}}</button>
    <mat-spinner class="smallspinner" *ngIf="loading"></mat-spinner>
  </div>
</div>
