import { DataverseObject } from '../dataverse.object';

export class ProjectMVP extends DataverseObject {
  ishtarProjectMVPId!: string;
  title!: string;
  discount!: number;
  project!: { id: string; name: string };

  get id(): string {
    return this.ishtarProjectMVPId;
  }

  constructor(obj: Partial<ProjectMVP>) {
    super(obj);
    Object.assign(this, obj);
  }
}
