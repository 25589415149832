<processdelight-action-bar
  [buttonActions]="(navButtons | async) || []"
  [navColor]="(navColor$ | async) || 'rgb(18, 68, 100)'"
  [navContrast]="(navContrast$ | async) || 'white'"
  [iconActions]="iconActions"
  (search)="search($event)"
  title="{{ taskGroup.value.name }}"
>
</processdelight-action-bar>

<div class="p-3 d-flex flex-column flex-grow-1 h-100 min-height-0">
  <h3>{{ taskGroup.value.name }}</h3>

  <mat-stepper
    [linear]="true"
    (selectionChange)="this.stepperIndex = $event.selectedIndex"
    [selectedIndex]="stepperIndex"
    #stepper
    class="flex-grow-1 min-height-0"
  >
    <mat-step [completed]="completed | async">
      <ng-template matStepLabel>{{
        translations.resourceAllocation
      }}</ng-template>
      <div class="pagewrapper content">
        <div class="allocation">
          <mat-tab-group class="tabgroup">
            <mat-tab label="{{ translations.user }}" class="userwindow">
              <div class="userwindow">
                <div class="users scrollbar" *ngIf="initialLoaded; else loader">
                  <div class="groupuser groupuserHeader">
                    <span>{{ translations.selected }}</span>
                    <span>{{ translations.name }}</span>
                    <span>{{ translations.userCapacity }}</span>
                    <span></span>
                    <span>{{ translations.total }}</span>
                  </div>
                  <div *ngFor="let group of usergroupsList$ | async">
                    <mat-divider class="mt-1" />
                    <div class="groupuser">
                      <mat-slide-toggle
                        class="ms-3"
                        [formControl]="group.controls.selected"
                      ></mat-slide-toggle>
                      <span class="pl-1">
                        {{ group.value.title }}
                      </span>
                      <div class="capacitytext">
                        {{ group.value.userCapacity | number : "1.2-2" }}
                      </div>
                      <p
                        class="text-center m-0"
                        *ngIf="group.controls.selected.value"
                      >
                        X
                      </p>
                      <div>
                        <mat-form-field
                          class="betterinput smallinput"
                          *ngIf="group.value.selected"
                        >
                          <input
                            matInput
                            type="number"
                            min="0"
                            max="100"
                            [formControl]="group.controls.taskCapacity"
                          />
                          <span matTextSuffix>%</span>
                        </mat-form-field>
                      </div>
                      <p
                        class="text-center m-0"
                        *ngIf="group.controls.selected.value"
                      >
                        =
                      </p>
                      <span style="text-align: center"
                        >{{ getUserTotal(group) | number : "1.2-2" }}
                      </span>
                    </div>
                  </div>
                </div>
                <ng-template #loader>
                  <div class="spinnerdiv">
                    <mat-spinner> </mat-spinner>
                  </div>
                </ng-template>
              </div>
            </mat-tab>
            <mat-tab label="{{ translations.machines }}" class="machinelist">
              <div class="machinestab">
                <div class="machines">
                  <span class="text-center">{{ translations.things }}</span>
                  <div
                    *ngFor="let thing of resourceThingValues | async"
                    (click)="
                      activeThingForm.setValue(
                        thing?.ishtarResourceThingId || ''
                      )
                    "
                    [class]="
                      this.activeThingForm.value === thing.ishtarResourceThingId
                        ? 'selected thing'
                        : 'thing'
                    "
                    [style.background-color]="
                      this.activeThingForm.value === thing.ishtarResourceThingId
                        ? (navColor$ | async)
                        : 'white'
                    "
                  >
                    {{ thing.name }}
                  </div>
                </div>
                <div class="functionwindow funcs scrollbar">
                  <div class="function groupuserHeader">
                    <span>{{ translations.name }}</span>
                    <span>{{ translations.capacity }}</span>
                    <span>{{ translations.time }}</span>
                  </div>
                  <div *ngFor="let func of filteredfunctions | async">
                    <mat-divider style="width: 550px; margin: auto" />
                    <div
                      (click)="selectFunction(func)"
                      [class]="
                        this.activeMachineForm.value ===
                        func.ishtarResourceFunctionId
                          ? 'selected function'
                          : 'function'
                      "
                      [style.background-color]="
                        this.activeMachineForm.value ===
                        func.ishtarResourceFunctionId
                          ? (navColor$ | async)
                          : 'white'
                      "
                    >
                      <span class="pl-1">
                        {{ func.name }}
                      </span>
                      <div class="capacitytext">
                        {{ func.capacity }}
                      </div>
                      <div class="capacitytext">
                        {{ func.timeAmount }}
                        {{ func.timeSort?.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{ translations.configureTask }}</ng-template>
      <div class="taskpreview content" *ngIf="tasksLoaded; else taskloader">
        <div class="taskconfig">
          <h3>{{ taskGroup.value.number }} {{ taskGroup.value.name }}</h3>
          <mat-form-field appearance="outline" class="betterinput mb-2">
            <mat-label>{{ translations.status }}</mat-label>
            <input matInput [formControl]="taskGroup.controls.status" />
            <span
              *ngIf="taskStatusIsFinalised"
              matSuffix
              class="me-2 grayouttext"
            >
              {{ translations.finalized }}
            </span>
          </mat-form-field>
          <div class="tasktime mb-1">
            <mat-form-field appearance="outline" class="betterinput">
              <mat-label>{{ translations.startTime }}</mat-label>
              <input matInput [formControl]="taskGroup.controls.startTime" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="betterinput">
              <mat-label>{{ translations.endTime }}</mat-label>
              <input matInput [formControl]="taskGroup.controls.endTime" />
            </mat-form-field>
          </div>
          <div>
            <mat-button-toggle-group
              [formControl]="taskGroup.controls.progressRegistrationType"
              name="fontStyle"
              class="w-100"
              aria-label="Font Style"
            >
              <mat-button-toggle
                *ngFor="let regType of registrationTypes | async"
                [value]="regType?.ishtarTaskRegistrationTypeId"
                >{{ regType.type }}</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
          <div>
            <div
              *ngIf="isRegTypeSelected('Manual')"
              [ngTemplateOutlet]="manual"
            ></div>
            <div
              *ngIf="isRegTypeSelected('Time')"
              [ngTemplateOutlet]="time"
            ></div>
            <div
              *ngIf="isRegTypeSelected('Ishtar.Time')"
              [ngTemplateOutlet]="time"
            ></div>
          </div>
          <ng-template #manual>
            <mat-progress-bar
              class="mt-3"
              mode="determinate"
              [value]="taskGroup.value.progress * 100"
            >
            </mat-progress-bar>
            <p>
              {{ translations.progress }}:
              {{ taskGroup.value.progress * 100 | number : "1.2-2" }}%
            </p>
          </ng-template>
          <ng-template #time>
            <div class="tasktime">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ translations.estimatedTime }}</mat-label>
                <input
                  matInput
                  [formControl]="taskGroup.controls.estimatedTime"
                />
              </mat-form-field>
              <p class="text-center m-0">-</p>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ translations.registeredTime }}</mat-label>
                <input
                  matInput
                  [formControl]="taskGroup.controls.completedTime"
                />
              </mat-form-field>
            </div>
          </ng-template>
          <div class="userlisttitle">
            <span>{{ translations.users }} / {{ translations.machines }}:</span>
            <span
              >{{ getTotal() | number : "1.2-2" }}
              {{ translations.hrsPerWeek }}</span
            >
          </div>
          <mat-divider> </mat-divider>
          <div class="userlist scrollbar">
            <div *ngIf="activeMachineForm.value !== ''" class="usersumarry">
              <div class="functionicon">
                <mat-icon>precision_manufacturing</mat-icon>
              </div>
              <span class="ms-2">{{ getSelectedMachine()?.name }}</span>
              <span
                >{{ getSelectedMachine()?.capacity | number : "1.2-2" }}
                {{ translations.hrsPerWeek }}
              </span>
            </div>
            <ng-container *ngFor="let userGroup of userArray.controls">
              <div *ngIf="userGroup.value.selected" class="usersumarry">
                <div>
                  <div
                    class="photo elem"
                    *ngIf="
                      getUser(userGroup.value.resourceUserId)?.user?.user
                        ?.photo;
                      let photo;
                      else: initials
                    "
                  >
                    <img
                      [src]="photo"
                      alt="user image"
                      [title]="userGroup.value.title"
                    />
                  </div>
                  <ng-template #initials>
                    <!--
                  <processdelight-user-initials
                    [size]="48"
                    [userName]="userGroup.value.title"
                  >
                  </processdelight-user-initials>
                -->
                  </ng-template>
                </div>
                <span class="usertext ms-2">
                  {{ userGroup.value.title }}
                </span>
                <span class="capacitytext">
                  {{ getUserTotal(userGroup) | number : "1.2-2" }}
                  {{ translations.hrsPerWeek }}
                </span>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="calculated"></div>
      </div>
      <ng-template #taskloader>
        <div class="spinnerdiv">
          <mat-spinner> </mat-spinner>
        </div>
      </ng-template>
    </mat-step>
  </mat-stepper>
</div>
