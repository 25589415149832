import { DateTime } from 'luxon';

export abstract class DataverseObject {
  abstract id: string;
  createdBy?: { id: string; name: string };
  createdOn?: DateTime;
  modifiedBy?: { id: string; name: string };
  modifiedOn?: DateTime;
  constructor(obj: Partial<DataverseObject>) {
    Object.assign(this, obj);
  }
}
