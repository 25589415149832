import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Project } from '../../models/task/project';
import { ProjectMVP } from '../../models/task/projectMVP';
import { Task } from '../../models/task/task';
import { WorkRegime } from '../../models/ishtar365/workregime';
import {
  addTaskResources,
  getProjectsMVPResolved,
  getProjectsResolved,
  getResourceCalc,
  getResourceCalcResolved,
  getTasksResolved,
  getWorkRegimesResolved,
  patchTaskResources,
  removeTaskResources,
  setTaskResources,
} from './task.action';

export const featureSlice = 'IshtarTask';

export interface State {
  tasks?: Task[];
  projects?: Project[];
  MVPs?: ProjectMVP[];
  regimes?: WorkRegime[];
}
const defaultState: State = {};

export function Reducer(state: State | undefined, action: Action) {
  return taskReducer(state, action);
}

export const initialState: State = defaultState;

export const taskState = (state: AppState) => state.coreFeature.IshtarTask;

export const taskReducer = createReducer(
  initialState,
  on(getResourceCalcResolved, (state, { tasks, projects }) => {
    return {
      ...state,
      tasks: tasks,
      projects: projects,
    };
  }),
  on(getTasksResolved, (state, { tasks }) => {
    return {
      ...state,
      tasks: tasks,
    };
  }),
  on(getProjectsResolved, (state, { projects }) => {
    return {
      ...state,
      projects: projects,
    };
  }),
  on(getWorkRegimesResolved, (state, { regimes }) => {
    return {
      ...state,
      regimes: regimes,
    };
  }),
  on(getProjectsMVPResolved, (state, { projects: mvps }) => ({
    ...state,
    MVPs: mvps,
    projects: state.projects?.map((p) => {
      const mvp = mvps.filter((proj) => proj.project.id === p.id);
      return mvp.length > 0
        ? new Project({
            ...p,
            mvps: mvp,
          })
        : p;
    }),
  })),
  on(setTaskResources, (state, { resources }) => {
    return {
      ...state,
      tasks: state.tasks?.map((t) =>
        resources.some((r) => r.task?.id === t.id)
          ? new Task({
              ...t,
              resources: resources.filter((r) => r.task?.id === t.id),
            })
          : t
      ),
    };
  }),
  on(addTaskResources, (state, { resources }) => {
    return {
      ...state,
      tasks: state.tasks?.map((t) =>
        resources.some((r) => r.task?.id === t.id)
          ? new Task({
              ...t,
              resources: t.resources.concat(
                resources.filter((r) => r.task?.id === t.id)
              ),
            })
          : t
      ),
    };
  }),
  on(patchTaskResources, (state, { resources, taskId }) => {
    return {
      ...state,
      tasks: state.tasks?.map((t) =>
        t.id === taskId ? new Task({ ...t, resources: resources }) : t
      ),
    };
  }),
  on(removeTaskResources, (state, { resources }) => {
    return {
      ...state,
      tasks: state.tasks?.map(
        (t) =>
          new Task({
            ...t,
            resources: t.resources.filter((r) => !resources.includes(r.id!)),
          })
      ),
    };
  })
);
