import { createAction, props } from '@ngrx/store';
import { ResourceThing } from '../../models/resource/resourceThing';
import { ResourceFunction } from '../../models/resource/resourceFunction';
import { ResourceUser } from '../../models/resource/resourceUser';
import { Resource } from '../../models/resource/resource';
import { AppConfig } from '../../models/app.config';

export const getInitialLoad = createAction(
  '[Tasks] Get data for initial load',
  props<{
    callback?: () => void;
  }>()
);

export const getInitialLoadResolved = createAction(
  '[Tasks] Load initial parameters into state',
  props<{
    resources: Resource[];
    resourceThings: ResourceThing[];
    resourceFunctions: ResourceFunction[];
    resourceUsers: ResourceUser[];
  }>()
);

export const getAppConfig = createAction(
  '[AppConfig] Get AppConfig',
  props<{
    callback?: () => void;
  }>()
);

export const getAppConfigResolved = createAction(
  '[AppConfig] Get AppConfig resolved',
  props<{
    appconfig: AppConfig;
  }>()
);

export const patchAppConfig = createAction(
  '[AppConfig] Patch AppConfig',
  props<{
    config: AppConfig;
    callback?: () => void;
  }>()
);

export const patchAppConfigResolved = createAction(
  '[AppConfig] Patch AppConfig resolved',
  props<{
    appconfig: AppConfig;
  }>()
);

export const getResource = createAction(
  '[Resource] Get Resources',
  props<{
    callback?: () => void;
  }>()
);

export const getResourceResolved = createAction(
  '[Resource] Get Resources resolved',
  props<{
    resources: Resource[];
  }>()
);

export const addResource = createAction(
  '[Resource] Add Resources',
  props<{ resources: Resource[]; callback?: () => void }>()
);

export const addResourceResolved = createAction(
  '[Resource] Add Resources resolved',
  props<{
    resources: Resource[];
  }>()
);

export const patchResources = createAction(
  '[Resource] Patch Resources',
  props<{ resources: Resource[]; taskId: string; callback?: () => void }>()
);

export const patchResourcesResolved = createAction(
  '[Resource] Patch Resources resolved',
  props<{
    resources: Resource[];
    taskId: string;
  }>()
);

export const removeResource = createAction(
  '[Resource] Remove Resources',
  props<{ resources: string[]; callback?: () => void }>()
);

export const removeResourceResolved = createAction(
  '[Resource] Remove Resources resolved',
  props<{
    resources: string[];
  }>()
);

export const getResourceThings = createAction(
  '[ResourceThing] Get ResourceThings',
  props<{
    callback?: () => void;
  }>()
);

export const getResourceThingsResolved = createAction(
  '[ResourceThing] Get ResourceThings resolved',
  props<{
    resourceThings: ResourceThing[];
  }>()
);

export const removeResourceThings = createAction(
  '[ResourceThing] Remove ResourceThing',
  props<{ ids: string[]; callback?: () => void }>()
);

export const removeResourceThingResolved = createAction(
  '[ResourceThing] Remove ResourceThing resolved',
  props<{ ishtarResourceThingIds: string[] }>()
);

export const updateResourceThings = createAction(
  '[ResourceThings] Update ResourceThings',
  props<{ resourceThings: ResourceThing[]; callback?: () => void }>()
);

export const updateResourceThingsResolved = createAction(
  '[ResourceThings] Update ResourceThings resolved',
  props<{ updatedResourceThings: ResourceThing[] }>()
);

export const addResourceThings = createAction(
  '[ResourceThings] Add ResourceThings',
  props<{
    resourceThings: ResourceThing[];
    callback?: (addedResourceThings: ResourceThing[]) => void;
  }>()
);

export const addResourceThingsResolved = createAction(
  '[ResourceThings] Add ResourceThings resolved',
  props<{ addedResourceThings: ResourceThing[] }>()
);

export const getResourceFunctions = createAction(
  '[ResourceFunctions] Get ResourceFunctions',
  props<{
    callback?: () => void;
  }>()
);

export const getResourceFunctionsResolved = createAction(
  '[ResourceFunctions] Get ResourceFunctions resolved',
  props<{
    resourceFunctions: ResourceFunction[];
  }>()
);

export const removeResourceFunctions = createAction(
  '[ResourceFunctions] Remove ResourceFunctions',
  props<{ ids: string[]; callback?: () => void }>()
);

export const removeResourceFunctionsResolved = createAction(
  '[ResourceFunctions] Remove ResourceFunctions resolved',
  props<{ ishtarResourceFunctionIds: string[] }>()
);

export const updateResourceFunctions = createAction(
  '[ResourceFunctions] Update ResourceFunctions',
  props<{ resourceFunctions: ResourceFunction[]; callback?: () => void }>()
);

export const updateResourceFunctionsResolved = createAction(
  '[ResourceFunctions] Update ResourceFunctions resolved',
  props<{ updatedResourceFunctions: ResourceFunction[] }>()
);

export const addResourceFunctions = createAction(
  '[ResourceFunctions] Add ResourceFunctions',
  props<{
    resourceFunctions: ResourceFunction[];
    callback?: () => void;
  }>()
);

export const addResourceFunctionsResolved = createAction(
  '[ResourceFunctions] Add ResourceFunctions resolved',
  props<{ addedResourceFunctions: ResourceFunction[] }>()
);

export const getResourceUser = createAction(
  '[ResourceUser] Get Resource user',
  props<{ callback?: () => void }>()
);

export const getResourceUserResolved = createAction(
  '[ResourceUser] Get Resource user resolved',
  props<{ users: ResourceUser[] }>()
);

export const addResourceUser = createAction(
  '[ResourceUser] Add Resource user',
  props<{ user: ResourceUser[]; callback?: () => void }>()
);

export const addResourceUserResolved = createAction(
  '[ResourceUser] Add Resource user resolved',
  props<{ user: ResourceUser[] }>()
);

export const patchResourceUser = createAction(
  '[ResourceUser] Patch a Resource user',
  props<{ user: ResourceUser[]; callback?: () => void }>()
);

export const patchResourceUserResolved = createAction(
  '[ResourceUser] Patch a Resource user resolved',
  props<{ user: ResourceUser[] }>()
);

export const deleteResourceUser = createAction(
  '[ResourceUser] Delete Resource user',
  props<{ user: string[]; callback?: () => void }>()
);

export const deleteResourceUserResolved = createAction(
  '[ResourceUser] Delete Resource user resolved',
  props<{ user: string[] }>()
);
