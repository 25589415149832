import { TimeSort } from './timesort';

export class ResourceFunction {
  ishtarResourceFunctionId?: string;
  name!: string;
  resourceThing?: { id: string; name: string };
  capacity?: number;
  timeAmount?: number;
  description?: string;
  timeSort?: { id: string; name: string };

  constructor(obj: Partial<ResourceFunction>) {
    delete (obj as any).title;
    delete (obj as any).id;
    Object.assign(this, obj);
  }

  toLookUp() {
    return {
      id: this.ishtarResourceFunctionId,
      name: this.name,
    };
  }
}
