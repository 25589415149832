import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  TranslationFile,
  TranslationService,
} from 'processdelight-angular-components';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  filter,
  first,
  of,
  takeUntil,
} from 'rxjs';
import { PopupService } from 'src/app/components/popup/popup.service';
import { CoreModule } from 'src/app/core.module';
import { ResourceFunction } from 'src/app/core/models/resource/resourceFunction';
import { ResourceThing } from 'src/app/core/models/resource/resourceThing';
import { TimeSort } from 'src/app/core/models/resource/timesort';
import { timeSorts$, users$ } from 'src/app/core/services/startup.service';
import { ResourceFacade } from 'src/app/core/store/resource/resource.facade';
import { v4 } from 'uuid';

@Component({
  selector: 'app-resource-thing-popup',
  standalone: true,
  imports: [CoreModule],
  templateUrl: './resource-thing-popup.component.html',
  styleUrls: ['./resource-thing-popup.component.scss'],
})
export class ResourceThingPopupComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  resourceThings = new FormArray<FormGroup>([]);

  translations: any;
  resourceUsers = this.resourceThingFacade.resourceUsers$;
  users = users$;
  timeSort$ = new BehaviorSubject<TimeSort[]>([]);
  activeResourceThing: ResourceThing;
  activeResourceThingGroup?: FormGroup;

  addingNewResource = false;
  newResourceControl = new FormControl('');

  activeResourceFunction?: FormGroup;

  resourceFunctions: ResourceFunction[] = [];

  constructor(
    private resourceThingFacade: ResourceFacade,
    private popup: PopupService,
    private dialogRef: MatDialogRef<ResourceThingPopupComponent>,
    private translate: TranslationService,
    @Inject(MAT_DIALOG_DATA) rt: ResourceThing
  ) {
    this.activeResourceThing = rt;
    this.resourceFunctions = rt?.resourceFunctions || [];
    this.translations = translate.translations;
  }

  ngOnInit(): void {
    timeSorts$
      .pipe(filter((t) => !!t))
      .subscribe((t) => this.timeSort$.next(t!));

    this.resourceThingFacade.resourceThings$
      .pipe(
        takeUntil(this.destroy$),
        filter((rt) => !!rt)
      )
      .subscribe((t) => {
        this.activeResourceThing =
          t?.find(
            (rt) =>
              rt.ishtarResourceThingId ===
              this.activeResourceThing.ishtarResourceThingId
          ) || this.activeResourceThing;
        this.initForms();
      });
  }

  initForms() {
    this.activeResourceThingGroup = new FormGroup({
      IshtarResourceThingId: new FormControl({
        value: this.activeResourceThing.ishtarResourceThingId,
        disabled: false,
      }),
      name: new FormControl({
        value: this.activeResourceThing.name,
        disabled: false,
      }),
      resourceFunctionLines: new FormArray(
        this.activeResourceThing.resourceFunctions?.map((e) => {
          const resourceFunctionLine = new FormGroup({
            ishtarResourceFunctionId: new FormControl({
              value: e.ishtarResourceFunctionId,
              disabled: false,
            }),
            name: new FormControl({
              value: e.name,
              disabled: false,
            }),
            description: new FormControl({
              value: e.description,
              disabled: false,
            }),
            capacity: new FormControl({
              value: e.capacity,
              disabled: false,
            }),
            timeAmount: new FormControl({
              value: e.timeAmount,
              disabled: false,
            }),
            timeSort: new FormControl({
              value: e.timeSort?.id || '',
              disabled: false,
            }),
          });
          if (
            this.activeResourceFunction?.value.ishtarResourceFunctionId ===
            e.ishtarResourceFunctionId
          )
            this.activeResourceFunction = resourceFunctionLine;
          return resourceFunctionLine;
        }) || []
      ),
    });
    // if (this.activeResourceFunction)
    //   this.activeResourceFunction =
    //     this.getThingResourceFunctionLineGroups().find(
    //       (g) =>
    //         g.value.ishtarResourceFunctionId ===
    //         this.activeResourceFunction?.value.ishtarResourceFunctionId
    //     );
  }

  selectFunc(fg?: FormGroup) {
    this.activeResourceFunction = fg;
  }

  getResourceFunction(fg: FormGroup) {
    return new ResourceFunction({
      ...fg,
      timeSort: timeSorts$.value
        ?.find(
          (t) =>
            t.ishtarResourceTimeSortId ===
            this.activeResourceFunction?.value.timeSort
        )
        ?.toLookup(),
    });
  }

  addingnew = false;
  addNewFunction() {
    this.addingnew = true;
    this.resourceThingFacade.addResourceFunctions(
      [
        new ResourceFunction({
          ishtarResourceFunctionId: v4(),
          name: 'new Function',
          description: '',
          capacity: 0,
          timeAmount: 0,
          // time sort id is not set -> post error
          timeSort: this.timeSort$.value[0].toLookup(),
          resourceThing: {
            id:
              this.activeResourceThingGroup?.value.IshtarResourceThingId || '',
            name: this.activeResourceThingGroup?.value.name || '',
          },
        }),
      ],
      () => (this.addingnew = false)
    );
  }

  firststage = false;
  loading = false;
  saveItem() {
    this.loading = true;
    const updateThings = new Subject<void>();
    const updateResource = new Subject<void>();
    this.resourceThingFacade.updateResourceThings(
      [
        new ResourceThing({
          ishtarResourceThingId:
            this.activeResourceThingGroup?.value.IshtarResourceThingId,
          name: this.activeResourceThingGroup?.value.name,
        }).prep(),
      ],
      () => updateThings.next()
    );
    const funcs = this.getThingResourceFunctionLineGroups().map((group) =>
      this.getResourceFunction(group.value)
    );
    this.resourceThingFacade.updateResourceFunctions(funcs, () =>
      updateResource.next()
    );
    combineLatest([updateThings, updateResource])
      .pipe(first())
      .subscribe(() => {
        this.loading = false;
        this.dialogRef.close();
      });
  }

  cancelFunc() {
    this.activeResourceFunction?.reset(
      this.resourceFunctions.find(
        (f) =>
          f.ishtarResourceFunctionId ===
          this.activeResourceFunction?.value.ishtarResourceFunctionId
      )
    );
    this.activeResourceFunction = undefined;
  }

  deleteFunc(fg: FormGroup) {
    this.resourceThingFacade.removeResourceFunction([
      fg.value.ishtarResourceFunctionId,
    ]);
  }

  getThingTitle(fg: FormGroup) {
    return fg.value.name;
  }

  getThingTitleControl() {
    return this.activeResourceThingGroup?.controls['name'] as FormControl;
  }

  getThingResourceFunctionLines(fg: FormGroup) {
    return (fg.controls['resourceFunctionLines'] as FormArray).controls;
  }
  getThingResourceFunctionLineGroups(): FormGroup[] {
    return (
      this.activeResourceThingGroup?.controls[
        'resourceFunctionLines'
      ] as FormArray
    ).controls as FormGroup[];
  }

  isActiveFunc(fg: FormGroup) {
    return (
      fg.value.ishtarResourceFunctionId ===
      this.activeResourceFunction?.value.ishtarResourceFunctionId
    );
  }

  getFunctionTitleControl(fg: FormGroup) {
    return fg.controls['name'] as FormControl;
  }

  getFunctionDescriptionControl(fg: FormGroup) {
    return fg.controls['description'] as FormControl;
  }

  getFunctionTimeAmount(fg: FormGroup) {
    return fg.controls['timeAmount'] as FormControl;
  }

  getFunctionTimeSort(fg: FormGroup) {
    return fg.controls['timeSort'] as FormControl;
  }

  getFunctionCapacity(fg: FormGroup) {
    return fg.controls['capacity'] as FormControl;
  }

  getTotalFunctionTimeAmount(fg: FormGroup) {
    return (((fg.controls['capacity'] as FormControl).value as number) *
      (fg.controls['timeAmount'] as FormControl).value) as number;
  }

  getTimeSortName(fg: FormGroup) {
    this.timeSort$.value.find(
      (ts) =>
        ts.ishtarResourceTimeSortId ===
        (fg.controls['timeSort'] as FormControl).value
    )?.sort;
  }

  canDeactivate() {
    return false;
  }

  showNavigationPopup() {
    return of(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close() {
    if (!this.activeResourceThingGroup?.pristine)
      this.popup.openPrevenNavigationPopup((close) => {
        if (close) this.dialogRef.close();
      });
    else this.dialogRef.close();
  }
}
