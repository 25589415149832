export class TimeSort {
  ishtarResourceTimeSortId?: string;
  sort?: string;
  constructor(obj: Partial<TimeSort>) {
    Object.assign(this, obj);
  }

  toLookup() {
    return {
      id: this.ishtarResourceTimeSortId || '',
      name: this.sort || '',
    };
  }
}
