import { Action, createReducer, on } from '@ngrx/store';
import { ResourceThing } from '../../models/resource/resourceThing';
import { AppState } from '../../../app.reducer';
import {
  getResourceThingsResolved,
  removeResourceThingResolved,
  updateResourceThingsResolved,
  addResourceThingsResolved,
  addResourceFunctionsResolved,
  getResourceFunctionsResolved,
  removeResourceFunctionsResolved,
  updateResourceFunctionsResolved,
  getResourceUserResolved,
  addResourceUserResolved,
  patchResourceUserResolved,
  deleteResourceUserResolved,
  getResourceResolved,
  addResourceResolved,
  patchResourcesResolved,
  removeResourceResolved,
  getAppConfigResolved,
  patchAppConfigResolved,
  getInitialLoadResolved,
} from './resource.actions';
import { ResourceFunction } from '../../models/resource/resourceFunction';
import { ResourceUser } from '../../models/resource/resourceUser';
import { Resource } from '../../models/resource/resource';
import { AppConfig } from '../../models/app.config';

export const featureSlice = 'resourceThing';

export interface State {
  appConfig?: AppConfig;
  resources?: Resource[];
  resourceThings?: ResourceThing[];
  resourceFunctions?: ResourceFunction[];
  resourceUsers?: ResourceUser[];
}
const defaultState: State = {};

export function Reducer(state: State | undefined, action: Action) {
  return resourceReducer(state, action);
}

export const initialState: State = defaultState;

const getThings = (r: ResourceThing[], f: ResourceFunction[]) => [
  ...r.map(
    (re) =>
      new ResourceThing({
        ...re,
        resourceFunctions: f.filter(
          (f) => f.resourceThing?.id === re.ishtarResourceThingId
        ),
      })
  ),
];

export const resourceReducer = createReducer(
  initialState,
  on(getAppConfigResolved, (state, { appconfig }) => ({
    ...state,
    appConfig: appconfig,
  })),
  on(
    getInitialLoadResolved,
    (
      state,
      { resources, resourceThings, resourceFunctions, resourceUsers }
    ) => ({
      ...state,
      resources: resources,
      resourceThings: resourceThings,
      resourceFunctions: resourceFunctions,
      resourceUsers: resourceUsers,
    })
  ),
  on(patchAppConfigResolved, (state, { appconfig }) => ({
    ...state,
    appConfig: appconfig,
  })),
  on(getResourceThingsResolved, (state, { resourceThings }) => ({
    ...state,
    resourceThings: getThings(resourceThings, state.resourceFunctions || []),
  })),
  on(removeResourceThingResolved, (state, { ishtarResourceThingIds }) => ({
    ...state,
    resourceThings:
      state.resourceThings?.filter(
        (s) => !ishtarResourceThingIds.includes(s.ishtarResourceThingId!)
      ) || [],
  })),
  on(updateResourceThingsResolved, (state, { updatedResourceThings }) => ({
    ...state,
    resourceThings: state.resourceThings?.map(
      (s) =>
        updatedResourceThings.find(
          (u) => s.ishtarResourceThingId === u.ishtarResourceThingId
        ) ?? s
    ),
  })),
  on(addResourceThingsResolved, (state, { addedResourceThings }) => ({
    ...state,
    resourceThings:
      state.resourceThings?.concat([...addedResourceThings]) ||
      addedResourceThings,
  })),
  on(getResourceFunctionsResolved, (state, { resourceFunctions }) => ({
    ...state,
    resourceThings: getThings(state.resourceThings || [], resourceFunctions),
    resourceFunctions: [...resourceFunctions],
  })),
  on(
    removeResourceFunctionsResolved,
    (state, { ishtarResourceFunctionIds }) => ({
      ...state,
      resourceThings: getThings(
        state.resourceThings || [],
        state.resourceFunctions?.filter(
          (p) =>
            !ishtarResourceFunctionIds.includes(p.ishtarResourceFunctionId!)
        ) || []
      ),
      resourceFunctions: state.resourceFunctions?.filter(
        (p) => !ishtarResourceFunctionIds.includes(p.ishtarResourceFunctionId!)
      ),
    })
  ),
  on(updateResourceFunctionsResolved, (state, { updatedResourceFunctions }) => {
    const funcs = state.resourceFunctions?.map(
      (p) =>
        updatedResourceFunctions.find(
          (u) => p.ishtarResourceFunctionId === u.ishtarResourceFunctionId
        ) ?? p
    );
    return {
      ...state,
      resourceThings: getThings(state.resourceThings || [], funcs || []),
      resourceFunctions: funcs,
    };
  }),
  on(addResourceFunctionsResolved, (state, { addedResourceFunctions }) => ({
    ...state,
    resourceThings: getThings(
      state.resourceThings || [],
      addedResourceFunctions.concat([...(state.resourceFunctions || [])])
    ),
    resourceFunctions: addedResourceFunctions.concat([
      ...(state.resourceFunctions || []),
    ]),
  })),
  on(getResourceUserResolved, (state, { users }) => ({
    ...state,
    resourceUsers: users,
  })),
  on(addResourceUserResolved, (state, { user }) => ({
    ...state,
    resourceUsers: state.resourceUsers?.concat(user) || user,
  })),
  on(patchResourceUserResolved, (state, { user }) => ({
    ...state,
    resourceUsers:
      state.resourceUsers?.map(
        (ru) =>
          user.find(
            (u) => u.ishtarResourceUserId === ru.ishtarResourceUserId
          ) ?? ru
      ) || [],
  })),
  on(deleteResourceUserResolved, (state, { user }) => ({
    ...state,
    resourceUsers:
      state.resourceUsers?.filter(
        (u) => !user.some((usr) => usr === u.ishtarResourceUserId)
      ) || [],
  })),
  on(getResourceResolved, (state, { resources }) => ({
    ...state,
    resources: resources,
  })),
  on(addResourceResolved, (state, { resources }) => ({
    ...state,
    resources: state.resources?.concat(resources) || resources,
  })),
  on(patchResourcesResolved, (state, { resources, taskId }) => ({
    ...state,
    resources:
      state.resources?.filter((r) => r.task?.id !== taskId).concat(resources) ||
      resources,
  })),
  on(removeResourceResolved, (state, { resources }) => ({
    ...state,
    resources:
      state.resources?.filter(
        (r) => !resources.some((res) => res === r.ishtarResourceId)
      ) || [],
  }))
);

export const resourceThingState = (state: AppState) =>
  state.coreFeature.resourceThing;
