import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AADUser,
  ContextMenuAction,
  Ishtar365CommunicationService,
  LoaderService,
  TranslationService,
} from 'processdelight-angular-components';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  of,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import { CoreModule } from 'src/app/core.module';
import { WorkRegime } from 'src/app/core/models/ishtar365/workregime';
import { Resource } from 'src/app/core/models/resource/resource';
import { ResourceFunction } from 'src/app/core/models/resource/resourceFunction';
import { ResourceThing } from 'src/app/core/models/resource/resourceThing';
import { ResourceUser } from 'src/app/core/models/resource/resourceUser';
import { Task, TaskRegistrationType } from 'src/app/core/models/task/task';
import {
  statuses$,
  taskRegistrationTypes$,
  varlicense$,
} from 'src/app/core/services/startup.service';
import { ResourceFacade } from 'src/app/core/store/resource/resource.facade';
import { TaskFacade } from 'src/app/core/store/task/task.facade';

import { v4 } from 'uuid';
import { PopupService } from '../../popup/popup.service';

type FilterObject = {
  operator: 'AND' | 'OR';
  filters: (FilterObject | string)[];
};
@Component({
  selector: 'app-resource-popup',
  standalone: true,
  imports: [CoreModule],
  templateUrl: './resource-popup.component.html',
  styleUrls: ['./resource-popup.component.scss'],
})
export class ResourcePopupComponent implements OnInit, OnDestroy {
  dateFormat = 'dd/MM/yyyy';

  translations: any;
  destroy$ = new Subject<void>();
  resourceUsers = new BehaviorSubject<ResourceUser[]>([]);
  functions = new BehaviorSubject<ResourceFunction[]>([]);
  resources = new BehaviorSubject<Resource[]>([]);
  tasks = new BehaviorSubject<Task[]>([]);
  flattasks = new BehaviorSubject<Task[]>([]);
  resourceThings = new BehaviorSubject<ResourceThing[]>([]);
  registrationTypes = new BehaviorSubject<TaskRegistrationType[]>([]);
  workRegimes = new BehaviorSubject<WorkRegime[]>([]);

  filteredfunctions = new BehaviorSubject<ResourceFunction[]>([]);

  taskForm = new FormControl('', [Validators.required]);

  taskComparator = (i: number, task: Task) => task.ishtarTaskId || '';

  @ViewChild('userMachine', { read: ElementRef }) element:
    | ElementRef
    | undefined;
  @ViewChild('stepper') stepper!: MatStepper;
  stepperIndex = 0;

  userMachineToggle = new FormControl<boolean>(false);

  userArray = new FormArray<
    FormGroup<{
      selected: FormControl;
      new: FormControl;
      ishtarResourceId: FormControl;
      title: FormControl;
      resourceUserId: FormControl;
      taskCapacity: FormControl;
      userCapacity: FormControl; // this is a temporary field
    }>
  >([]);

  filteredArray: any;

  resourceThingValues = new BehaviorSubject<ResourceThing[]>([]);
  activeThingForm = new FormControl('');
  activeMachineForm = new FormControl('', { validators: Validators.required });
  filterValue = new BehaviorSubject<string>('');

  showErrors = false;
  initialLoaded = false;
  tasksLoaded = false;
  taskStatusIsFinalised = false;

  enabledUserFormGroups$ = this.userArray.valueChanges.pipe(
    takeUntil(this.destroy$),
    switchMap((arr) => {
      return of(this.userArray).pipe(
        map((group) => group.controls.filter((g) => g.value.selected))
      );
    })
  );

  usergroupsList$ = combineLatest([
    this.userArray.valueChanges.pipe(startWith([])),
    this.filterValue,
  ]).pipe(
    debounceTime(300),
    takeUntil(this.destroy$),
    switchMap((arr) => {
      const userList: AADUser[] = [];
      this.resourceThingFacade.resourceUsers$.subscribe((u) => {
        u?.forEach((ru) => {
          if (ru.user?.user) {
            const user = ru.user.user as AADUser;
            userList.push(user);
          }
        });
      });

      const filteringList = this.searchUsers(this.filterValue.value, userList);

      return of(this.userArray).pipe(
        map((group) =>
          group.controls.filter((g: FormGroup) => {
            const title = g.value.title.toLowerCase();
            return filteringList.some((name) =>
              name.displayName.toLowerCase().includes(title)
            );
          })
        )
      );
    })
  );

  taskGroup = new FormGroup<{
    ishtarTaskId: FormControl;
    name: FormControl;
    approvalFlow: FormControl;
    completedTime: FormControl;
    createdOn: FormControl;
    deadline: FormControl;
    decision: FormControl;
    description: FormControl;
    startTime: FormControl;
    endTime: FormControl;
    estimatedTime: FormControl;
    executors: FormControl;
    isDeleted: FormControl;
    isTaskTemplate: FormControl;
    logType: FormControl;
    number: FormControl;
    partentTask: FormControl;
    priorty: FormControl;
    progress: FormControl;
    progressRegistrationType: FormControl;
    project: FormControl;
    skill: FormControl;
    status: FormControl;
    statusid: FormControl;
    type: FormControl;
  }>({
    ishtarTaskId: new FormControl(''),
    name: new FormControl(''),
    approvalFlow: new FormControl(''),
    completedTime: new FormControl(''),
    createdOn: new FormControl(''),
    deadline: new FormControl(''),
    decision: new FormControl(''),
    description: new FormControl(''),
    startTime: new FormControl(''),
    endTime: new FormControl(''),
    estimatedTime: new FormControl(''),
    executors: new FormControl(''),
    isDeleted: new FormControl(''),
    isTaskTemplate: new FormControl(''),
    logType: new FormControl(''),
    number: new FormControl(''),
    partentTask: new FormControl(''),
    priorty: new FormControl(''),
    progress: new FormControl(''),
    progressRegistrationType: new FormControl(''),
    project: new FormControl(''),
    skill: new FormControl(''),
    status: new FormControl(''),
    statusid: new FormControl(''),
    type: new FormControl(''),
  });

  completed = new BehaviorSubject<boolean>(false);

  navColor$ = varlicense$.pipe(map((u) => u?.navColor));
  navContrast$ = varlicense$.pipe(map((u) => u?.navContrast));
  navButtons = this.completed.pipe(
    takeUntil(this.destroy$),
    map((d) => [
      new ContextMenuAction({
        label: this.translations.save,
        disabled: !d,
        action: () => {
          this.saveResource();
        },
      }),
      new ContextMenuAction({
        label: this.translations.next,
        disabled: !d,
        action: () => {
          this.nextPage();
        },
      }),
      new ContextMenuAction({
        label: this.translations.goToIshtarTasks,
        icon: 'open_in_new',
        action: () => {
          this.goToTasks();
        },
      }),
    ])
  );

  iconActions: ContextMenuAction<unknown>[] = [
    new ContextMenuAction<unknown>({
      label: 'Color settings',
      icon: 'close',
      iconOutline: true,
      action: () => {
        this.close();
      },
    }),
  ];

  totalControl = new FormGroup([this.userArray, this.activeMachineForm]);

  constructor(
    private resourceThingFacade: ResourceFacade,
    private taskFac: TaskFacade,
    private popup: PopupService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<ResourcePopupComponent>,
    private loader: LoaderService,
    private translation: TranslationService,
    private ishtar365com: Ishtar365CommunicationService,
    @Inject(MAT_DIALOG_DATA) rt: string | undefined
  ) {
    this.translations = translation.translations;
    this.setTaskIdPathParam(rt);
    this.taskForm.setValue(rt || '', { emitEvent: false });

    combineLatest([this.tasks, this.resources])
      .pipe(
        filter(([t, r]) => t.length != 0 && r.length != 0),
        first()
      )
      .subscribe(([t, r]) => {
        this.updateForm(rt || '');
      });

    this.taskGroup.controls.startTime.disable();
    this.taskGroup.controls.endTime.disable();
    this.taskGroup.controls.deadline.disable();
    this.taskGroup.controls.estimatedTime.disable();
    this.taskGroup.controls.completedTime.disable();
    this.taskGroup.controls.progressRegistrationType.disable();
    this.taskGroup.controls.status.disable();
  }

  ngOnInit(): void {
    combineLatest([
      this.resourceThingFacade.resourceUsers$,
      this.taskFac.workRegimes$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(([u, w]) => !!u && !!w)
      )
      .subscribe(([u]) => {
        this.resourceUsers.next(u!);
        while (this.userArray.length !== 0) {
          this.userArray.removeAt(0);
        }
        u?.forEach((ru) => {
          this.userArray.push(
            new FormGroup({
              selected: new FormControl(false),
              new: new FormControl(true),
              ishtarResourceId: new FormControl(''),
              resourceUserId: new FormControl(ru.ishtarResourceUserId || ''),
              title: new FormControl(ru.user?.user?.displayName || ''),
              taskCapacity: new FormControl(0),
              userCapacity: new FormControl(
                this.getUserCap(ru.ishtarResourceUserId || '')
              ),
            })
          );
        });
        this.userArray.markAsPristine();
        this.userArray.markAsUntouched();
        this.updateResourceSelector();
      });

    combineLatest([
      this.userArray.valueChanges,
      this.activeMachineForm.valueChanges.pipe(startWith('')),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([users, machine]) => {
        const valid = users.some((u) => u.selected) || machine !== '';
        this.completed.next(valid);
      });

    this.resourceThingFacade.resourceFunctions$
      .pipe(
        takeUntil(this.destroy$),
        filter((f) => !!f)
      )
      .subscribe((f) => this.functions.next(f!));

    combineLatest([
      this.functions,
      this.activeThingForm.valueChanges,
      this.filterValue,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([f, val, value]) => {
          const filter1 = f.filter((func) =>
            val !== ''
              ? func.resourceThing?.id === val
              : func.resourceThing == undefined
          );
          return this.searchFunctions(value, filter1);
        })
      )
      .subscribe((f) => this.filteredfunctions.next(f));

    this.taskFac.tasks$
      .pipe(
        takeUntil(this.destroy$),
        filter((t) => !!t)
      )
      .subscribe((t) => {
        this.tasks.next(t!);
        this.tasksLoaded = true;
      });

    this.taskFac.tasksFlat$
      .pipe(
        takeUntil(this.destroy$),
        filter((t) => !!t)
      )
      .subscribe((t) => {
        this.flattasks.next(t!);
      });

    this.resourceThingFacade.resources$
      .pipe(
        takeUntil(this.destroy$),
        filter((r) => !!r)
      )
      .subscribe((r) => {
        this.resources.next(r!);
        this.updateResourceSelector();
        this.initialLoaded = true;
      });

    this.resourceThingFacade.resourceThings$
      .pipe(
        takeUntil(this.destroy$),
        filter((r) => !!r)
      )
      .subscribe((r) => this.resourceThings.next(r!));

    this.resourceThings
      .pipe(
        takeUntil(this.destroy$),
        map((t) =>
          t.concat([
            new ResourceThing({ name: 'Others', ishtarResourceThingId: '' }),
          ])
        )
      )
      .subscribe((t) => this.resourceThingValues.next(t));

    taskRegistrationTypes$
      .pipe(
        takeUntil(this.destroy$),
        filter((t) => !!t)
      )
      .subscribe((t) => {
        this.registrationTypes.next(t!);
      });

    this.taskFac.workRegimes$
      .pipe(
        takeUntil(this.destroy$),
        filter((t) => !!t)
      )
      .subscribe((t) => this.workRegimes.next(t!));

    combineLatest([this.resources, this.resourceUsers])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateResourceSelector());

    combineLatest([this.taskGroup.controls.statusid.valueChanges, statuses$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([v, statuses]) => {
        this.taskStatusIsFinalised =
          statuses?.find((s) => s.id === v)?.isFinalState || false;
      });
  }

  searchFunctions(
    value: string,
    functionList: ResourceFunction[]
  ): ResourceFunction[] {
    if (value.trim() === '') return functionList;

    const filterObject = this.subFilter(value);

    return this.filterFunctions(filterObject, functionList);
  }

  filterFunctions(
    filter: FilterObject | string,
    funtionArray: ResourceFunction[]
  ): ResourceFunction[] {
    return funtionArray.filter((f) => {
      const title = f.name.toLowerCase();

      if (typeof filter === 'string') {
        // Include user if it satisfies the filter
        return title.includes(filter.toLowerCase());
      } else {
        // Include user if it satisfies the filter
        const rootSatisfies =
          (filter as FilterObject).operator === 'AND'
            ? (filter as FilterObject).filters.every(
                (subFilter) => this.filterFunctions(subFilter, [f]).length > 0
              )
            : (filter as FilterObject).filters.some(
                (subFilter) => this.filterFunctions(subFilter, [f]).length > 0
              );

        return rootSatisfies;
      }
    });
  }

  searchUsers(value: string, userList: AADUser[]): AADUser[] {
    if (value.trim() === '') return userList;

    const filterObject = this.subFilter(value);

    return this.filterUsers(filterObject, userList);
  }

  filterUsers(filter: FilterObject | string, userArray: AADUser[]): AADUser[] {
    return userArray.filter((user) => {
      const title = user.displayName.toLowerCase();

      if (typeof filter === 'string') {
        // Include user if it satisfies the filter
        return title.includes(filter.toLowerCase());
      } else {
        // Include user if it satisfies the filter
        const rootSatisfies =
          (filter as FilterObject).operator === 'AND'
            ? (filter as FilterObject).filters.every(
                (subFilter) => this.filterUsers(subFilter, [user]).length > 0
              )
            : (filter as FilterObject).filters.some(
                (subFilter) => this.filterUsers(subFilter, [user]).length > 0
              );

        return rootSatisfies;
      }
    });
  }

  subFilter(value: string): FilterObject | string {
    value = this.removeOuterBrackets(value);

    if (!(/ AND /.test(value) || / OR /.test(value))) {
      return value;
    }

    const result: FilterObject = {
      operator: 'AND', // You can choose 'AND' or 'OR' based on your needs
      filters: [],
    };

    let next: string[] = [];
    let foundAND = false;

    if (/ AND (?![^()]*\))/.test(value)) {
      next = value.split(/ AND (?![^()]*\))/); // split on ' AND ' that is not inside parentheses
      foundAND = true;
    } else if (/ OR (?![^()]*\))/.test(value)) {
      next = value.split(/ OR (?![^()]*\))/); // split on ' OR ' that is not inside parentheses
    }

    if (!foundAND) {
      result.operator = 'OR';
    }

    for (const n of next) {
      if (!(n[0] === '(' && n[n.length - 1] === ')')) {
        result.filters.push(this.subFilter(n));
      } else {
        result.filters.push(this.subFilter(this.removeOuterBrackets(n))); // Convert the array to a string
      }
    }
    return result;
  }

  removeOuterBrackets(inputString: string): string {
    const bracketRegex = /^\(.*\)$/;

    if (bracketRegex.test(inputString)) {
      return inputString.slice(2, -2);
    }

    return inputString;
  }

  goToTasks(): void {
    this.ishtar365com.redirectToApp(
      'Ishtar.Tasks',
      'openTask',
      this.taskGroup.get('ishtarTaskId')?.value ?? ''
    );
  }

  updateForm(taskId: string | undefined) {
    const t = this.tasks.value.find((t) => t.ishtarTaskId === taskId);
    this.taskGroup.setValue({
      ishtarTaskId: t?.ishtarTaskId || '',
      name: t?.title || '',
      approvalFlow: t?.approvalFlow || '',
      completedTime: t?.completedTime || '',
      createdOn: t?.createdOn || '',
      deadline: t?.deadline?.toFormat(this.dateFormat) || '',
      decision: t?.decision?.id || '',
      description: t?.description || '',
      startTime: t?.startTime?.toFormat(this.dateFormat) || '',
      endTime: t?.endTime?.toFormat(this.dateFormat) || '',
      estimatedTime: t?.estimatedTime || '',
      executors: t?.executors || '',
      isDeleted: t?.isDeleted || '',
      isTaskTemplate: t?.isTaskTemplate || '',
      logType: t?.logType?.id || '',
      number: t?.number || '',
      partentTask: t?.parentTask?.id || '',
      priorty: t?.priorty?.id || '',
      progress: t?.progress || '',
      progressRegistrationType: t?.progressRegistrationType?.id || '',
      project: t?.project?.id || '',
      skill: t?.skill?.id || '',
      status: t?.status?.name || '',
      statusid: t?.status?.id || '',
      type: t?.type?.id || '',
    });

    this.taskStatusIsFinalised =
      statuses$.value?.find((s) => s.id === t?.status?.id)?.isFinalState ||
      false;
  }

  updateResourceSelector() {
    const r = this.resources.value.find(
      (res) => res.task?.id === this.taskForm.value && res.function != null
    );
    const f = this.functions.value.find(
      (func) =>
        func.ishtarResourceFunctionId === r?.function?.ishtarResourceFunctionId
    );

    this.activeMachineForm.setValue(
      r?.function?.ishtarResourceFunctionId || ''
    );

    this.activeThingForm.setValue(f?.resourceThing?.id || '');

    this.userArray.controls.forEach((control) => {
      const r = this.resources.value.find(
        (res) =>
          res.task?.id === this.taskForm.value &&
          res.resourceUser?.ishtarResourceUserId ===
            control.value.resourceUserId
      );
      control.patchValue({
        selected: r !== undefined || control.value.selected,
        new: r === undefined,
        ishtarResourceId: r?.ishtarResourceId || v4(),
        taskCapacity: r?.taskCapacity ? r.taskCapacity * 100 : 100,
        userCapacity: this.getUserCap(control.value.resourceUserId),
      });
    });
  }

  selectFunction(ru: ResourceFunction) {
    if (this.activeMachineForm.value === ru.ishtarResourceFunctionId)
      this.activeMachineForm.setValue('');
    else this.activeMachineForm.setValue(ru.ishtarResourceFunctionId || '');
  }

  getUserResource = (
    group: FormGroup<{
      selected: FormControl;
      new: FormControl;
      ishtarResourceId: FormControl;
      title: FormControl;
      resourceUserId: FormControl;
      taskCapacity: FormControl;
      userCapacity: FormControl;
    }>
  ) => {
    return new Resource({
      ishtarResourceId: group.value.ishtarResourceId,
      title: group.value.title,
      task: this.tasks.value
        .find((t) => t.ishtarTaskId === this.taskForm.value)
        ?.toLookUp(),
      resourceUser: this.resourceUsers.value.find(
        (r) => r.ishtarResourceUserId === group.value.resourceUserId
      ),
      taskCapacity: group.value.taskCapacity / 100,
    });
  };

  savingResources = false;
  saveResource() {
    if (this.totalControl.errors) return;
    this.savingResources = true;
    const res = this.userArray.controls
      .filter((g) => g.value.selected)
      .map((group) => this.getUserResource(group));
    if (this.activeMachineForm.value !== '')
      res.push(
        new Resource({
          ishtarResourceId: v4(),
          title: 'Function Resource',
          task: this.tasks.value
            .find((t) => t.ishtarTaskId === this.taskForm.value)
            ?.toLookUp(),
          resourceUser: undefined,
          taskCapacity: 100,
          function: this.functions.value.find(
            (f) => f.ishtarResourceFunctionId === this.activeMachineForm.value
          ),
        })
      );
    const loaderSubj = new Subject<void>();
    this.loader.startLoading('Saving resources', () => loaderSubj);
    this.resourceThingFacade.patchResources(res, this.taskForm.value!, () => {
      this.dialogRef.close();
      loaderSubj.next();
      loaderSubj.complete();
    });

    this.totalControl.markAsPristine();
  }

  getUserCap(resourceUserId: string): number {
    const resourceUser = this.resourceUsers.value.find(
      (r) => r.ishtarResourceUserId === resourceUserId
    );
    const resourceCap = resourceUser?.capacity || 1;
    const totalcap =
      this.workRegimes.value.find(
        (r) => r.user?.user?.id === resourceUser?.user?.user?.id
      )?.capacity ?? 40;
    return resourceCap * totalcap;
  }

  getUserTotal(
    formGroup: FormGroup<{
      selected: FormControl<any>;
      new: FormControl<any>;
      ishtarResourceId: FormControl<any>;
      title: FormControl<any>;
      resourceUserId: FormControl<string>;
      taskCapacity: FormControl<number>;
      userCapacity: FormControl<number>;
    }>
  ): number | undefined {
    if (!formGroup.value.selected) return undefined;
    const usercap = formGroup.value.userCapacity;
    const formcap = formGroup.value.taskCapacity
      ? formGroup.value.taskCapacity / 100
      : undefined;
    return usercap && formcap ? usercap * formcap : undefined;
  }

  setTaskIdPathParam(id: string | undefined | null) {
    if (!id) return;
    const queryParams: Params = { task: id };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  //// Configure task form

  getGroupUser(userGroup: FormGroup) {
    return this.resourceUsers.value.find(
      (r) => r.ishtarResourceUserId === userGroup.value.resourceUserId
    );
  }

  getSelectedUserForms() {
    return this.userArray.controls.filter((g) => g.value.selected);
  }

  getSelectedMachine() {
    return this.functions.value.find(
      (f) => f.ishtarResourceFunctionId === this.activeMachineForm.value
    );
  }

  isRegTypeSelected(RegTypeName: 'Ishtar.Time' | 'Manual' | 'Time') {
    const v = this.registrationTypes.value.find(
      (r) =>
        r.ishtarTaskRegistrationTypeId ===
        this.taskGroup.controls.progressRegistrationType.value
    );
    return v ? v.type === RegTypeName : false;
  }

  getUser(resourceUserId: string) {
    const resourceUser = this.resourceUsers.value.find(
      (r) => r.ishtarResourceUserId === resourceUserId
    );
    const regime = this.workRegimes.value.find(
      (r) => r?.user?.user?.id === resourceUser?.user?.user?.id
    );
    return regime;
  }

  getTotal() {
    return (
      this.userArray.controls
        .map((g) => this.getUserTotal(g))
        .filter((p) => !!p)
        .reduce((a, b) => a! + b!, 0)! +
      (this.getSelectedMachine()?.capacity || 0)
    );
  }

  search(value: string): void {
    this.filterValue.next(value);
  }

  ////

  nextPage(): void {
    this.stepper.next();
  }

  close() {
    if (!this.totalControl?.pristine)
      this.popup.openPrevenNavigationPopup((close) => {
        if (close) this.dialogRef.close(this.taskForm.value || null);
      });
    else this.dialogRef.close(this.taskForm.value || null);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.router.navigate([], {
      queryParams: {
        task: null,
      },
      queryParamsHandling: 'merge',
    });
  }
}
