import { DateTime } from 'luxon';
import { GroupUser } from 'processdelight-angular-components';

export class ResourceUser {
  ishtarResourceUserId!: string;
  createdOn!: DateTime;
  user?: GroupUser;
  capacity?: number;

  constructor(obj: Partial<ResourceUser>) {
    delete (obj as any).name;
    delete (obj as any).id;
    Object.assign(this, obj);
  }

  toLookup() {
    return {
      id: this.ishtarResourceUserId,
      name: this.user?.user?.id ? 'user://' + this.user.user.id : '',
    };
  }
}
