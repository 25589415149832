import { DataverseObject } from '../dataverse.object';

export class Status extends DataverseObject {
  override id!: string;
  status!: string;
  isFinalState!: boolean;
  inShortMenu!: boolean;
  type!: { id: string; name: string };

  constructor(obj: Partial<Status>) {
    super(obj);
    Object.assign(this, obj);
  }
}
