import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  addResourceThings,
  addResourceThingsResolved,
  getResourceThings,
  getResourceThingsResolved,
  removeResourceThings,
  removeResourceThingResolved,
  updateResourceThings,
  updateResourceThingsResolved,
  addResourceFunctions,
  addResourceFunctionsResolved,
  getResourceFunctions,
  getResourceFunctionsResolved,
  removeResourceFunctions,
  removeResourceFunctionsResolved,
  updateResourceFunctions,
  updateResourceFunctionsResolved,
  getResourceUser,
  getResourceUserResolved,
  addResourceUser,
  addResourceUserResolved,
  patchResourceUser,
  patchResourceUserResolved,
  deleteResourceUser,
  deleteResourceUserResolved,
  getResource,
  getResourceResolved,
  addResource,
  addResourceResolved,
  patchResources,
  patchResourcesResolved,
  removeResource,
  removeResourceResolved,
  getAppConfig,
  getAppConfigResolved,
  patchAppConfig,
  patchAppConfigResolved,
  getInitialLoad,
  getInitialLoadResolved,
} from './resource.actions';
import { IshtarResourcePlanningService } from '../../services/resourceplanning.service';
import {
  addTaskResources,
  patchTaskResources,
  removeTaskResources,
  setTaskResources,
} from '../task/task.action';

@Injectable({ providedIn: 'root' })
export class ResourceThingEffects {
  constructor(
    private actions$: Actions,
    private ishtarResourceService: IshtarResourcePlanningService
  ) {}

  getAppConfig = createEffect(() =>
    this.actions$.pipe(
      ofType(getAppConfig),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getConfig().pipe(
          switchMap((config) =>
            of(
              getAppConfigResolved({
                appconfig: config,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getInitialLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(getInitialLoad),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getInitialLoad().pipe(
          switchMap((data) => of(getInitialLoadResolved(data))),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  patchAppConfig = createEffect(() =>
    this.actions$.pipe(
      ofType(patchAppConfig),
      switchMap(({ config, callback }) =>
        this.ishtarResourceService.saveConfig(config).pipe(
          switchMap((config) =>
            of(
              patchAppConfigResolved({
                appconfig: config,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getResource = createEffect(() =>
    this.actions$.pipe(
      ofType(getResource),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getResources().pipe(
          switchMap((resources) => [
            getResourceResolved({
              resources,
            }),
            setTaskResources({ resources }),
          ]),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addResource = createEffect(() =>
    this.actions$.pipe(
      ofType(addResource),
      switchMap(({ resources, callback }) =>
        this.ishtarResourceService.addResources(resources).pipe(
          switchMap((resources) => [
            addResourceResolved({
              resources,
            }),
            addTaskResources({ resources }),
          ]),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  patchResource = createEffect(() =>
    this.actions$.pipe(
      ofType(patchResources),
      switchMap(({ resources, taskId, callback }) =>
        this.ishtarResourceService.patchResources(resources, taskId).pipe(
          switchMap((resources) => [
            patchResourcesResolved({
              resources,
              taskId,
            }),
            patchTaskResources({ resources, taskId }),
          ]),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  deleteResource = createEffect(() =>
    this.actions$.pipe(
      ofType(removeResource),
      switchMap(({ resources, callback }) =>
        this.ishtarResourceService.deleteResources(resources).pipe(
          switchMap((resources) => [
            removeResourceResolved({
              resources,
            }),
            removeTaskResources({ resources }),
          ]),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getResourceThings = createEffect(() =>
    this.actions$.pipe(
      ofType(getResourceThings),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getResourceThings().pipe(
          switchMap((resourceThings) =>
            of(
              getResourceThingsResolved({
                resourceThings,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addResourceThings = createEffect(() =>
    this.actions$.pipe(
      ofType(addResourceThings),
      switchMap(({ resourceThings, callback }) =>
        this.ishtarResourceService.addResourceThings(resourceThings).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedResourceThings) =>
            of(addResourceThingsResolved({ addedResourceThings }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  updateResourceThings = createEffect(() =>
    this.actions$.pipe(
      ofType(updateResourceThings),
      switchMap(({ resourceThings, callback }) =>
        this.ishtarResourceService.updateResourceThings(resourceThings).pipe(
          tap(() => (callback ? callback() : undefined)),
          switchMap((updatedResourceThings) =>
            of(updateResourceThingsResolved({ updatedResourceThings }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  removeResourceThings = createEffect(() =>
    this.actions$.pipe(
      ofType(removeResourceThings),
      switchMap(({ ids, callback }) =>
        this.ishtarResourceService.removeResourceThings(ids).pipe(
          switchMap((ishtarResourceThingIds) =>
            of(removeResourceThingResolved({ ishtarResourceThingIds }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getResourceFunctions = createEffect(() =>
    this.actions$.pipe(
      ofType(getResourceFunctions),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getResourceFunctions().pipe(
          switchMap((resourceFunctions) =>
            of(
              getResourceFunctionsResolved({
                resourceFunctions,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addResourceFunctions = createEffect(() =>
    this.actions$.pipe(
      ofType(addResourceFunctions),
      switchMap(({ resourceFunctions, callback }) =>
        this.ishtarResourceService.addResourceFunctions(resourceFunctions).pipe(
          switchMap((addedResourceFunctions) =>
            of(addResourceFunctionsResolved({ addedResourceFunctions }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  updateResourceFunctions = createEffect(() =>
    this.actions$.pipe(
      ofType(updateResourceFunctions),
      switchMap(({ resourceFunctions, callback }) =>
        this.ishtarResourceService
          .updateResourceFunctions(resourceFunctions)
          .pipe(
            tap(() => (callback ? callback() : undefined)),
            switchMap((updatedResourceFunctions) =>
              of(updateResourceFunctionsResolved({ updatedResourceFunctions }))
            ),
            catchError((e) => [])
          )
      )
    )
  );

  removeResourceFunctions = createEffect(() =>
    this.actions$.pipe(
      ofType(removeResourceFunctions),
      switchMap(({ ids, callback }) =>
        this.ishtarResourceService.removeResourceFunctions(ids).pipe(
          switchMap((ishtarResourceFunctionIds) =>
            of(removeResourceFunctionsResolved({ ishtarResourceFunctionIds }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getResourceUser = createEffect(() =>
    this.actions$.pipe(
      ofType(getResourceUser),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getResourceUsers().pipe(
          switchMap((users) => of(getResourceUserResolved({ users }))),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addResourceUser = createEffect(() =>
    this.actions$.pipe(
      ofType(addResourceUser),
      switchMap(({ user, callback }) =>
        this.ishtarResourceService.addResourceUser(user).pipe(
          switchMap((user) => of(addResourceUserResolved({ user }))),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => {
            console.log(e);
            return [];
          })
        )
      )
    )
  );

  patchResourceUser = createEffect(() =>
    this.actions$.pipe(
      ofType(patchResourceUser),
      switchMap(({ user, callback }) =>
        this.ishtarResourceService.patchResourceUser(user).pipe(
          switchMap((user) => of(patchResourceUserResolved({ user }))),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  deleteResourceUser = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteResourceUser),
      switchMap(({ user, callback }) =>
        this.ishtarResourceService.deleteResourceUser(user).pipe(
          switchMap((user) => of(deleteResourceUserResolved({ user }))),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );
}
