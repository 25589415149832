import { GroupUser } from 'processdelight-angular-components';
import { DataverseObject } from '../dataverse.object';

export class WorkRegime extends DataverseObject {
  id!: string;
  user!: GroupUser;
  capacity!: number;
  constructor(obj: Partial<WorkRegime>) {
    super(obj);
    Object.assign(this, obj);
  }
}
