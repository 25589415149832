import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { enableProdMode, importProvidersFrom, inject } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { provideStore, StoreModule } from '@ngrx/store';
import {
  provideStoreDevtools,
  StoreDevtoolsModule,
} from '@ngrx/store-devtools';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { StartUpService } from './app/core//services/startup.service';
import {
  APP_STARTUP,
  ISHTAR365_APP_IDENTIFIER,
  ISHTAR365_CLIENTID,
  ISHTAR365_INSTANCE,
  ISHTAR365_URL,
  MsalModule,
  ISHTAR365_INITIAL_ROUTE,
  ISHTAR365_LAST_ROUTE_KEY,
  ISHTAR365_FUNCTIONS_SCOPE,
  microsoftAuthenticationInterceptor,
  ISHTAR365_FUNCTIONS_URL,
} from 'processdelight-angular-components';
import { appRoutes } from './app/core/routes.module';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: ISHTAR365_CLIENTID, useValue: environment.clientId },
    { provide: ISHTAR365_INSTANCE, useValue: environment.instance },
    { provide: ISHTAR365_URL, useValue: environment.ishtar365 },
    { provide: ISHTAR365_APP_IDENTIFIER, useFactory: () => location.origin },
    { provide: ISHTAR365_INITIAL_ROUTE, useValue: ['planning'] },
    {
      provide: ISHTAR365_FUNCTIONS_URL,
      useValue: environment.ishtarFunctions,
    },
    {
      provide: ISHTAR365_FUNCTIONS_SCOPE,
      useValue: environment.ishtarFunctionsScope,
    },
    {
      provide: ISHTAR365_LAST_ROUTE_KEY,
      useValue: 'Ishtar.Resource-LastRouteKey',
    },
    {
      provide: APP_STARTUP,
      useFactory: () => {
        const service = inject(StartUpService);
        return () => service.boot();
      },
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true, firstDayOfWeek: 1 },
    },
    provideRouter(appRoutes),
    provideStore({}),
    provideEffects([]),
    provideStoreDevtools({ logOnly: false }),
    provideHttpClient(
      withInterceptors([microsoftAuthenticationInterceptor('')])
    ),
    importProvidersFrom(
      StoreModule.forRoot({}),
      EffectsModule.forRoot([]),
      StoreDevtoolsModule.instrument({ logOnly: environment.production }),
      MsalModule
    ),
    provideAnimations(),
  ],
});
